import { createGlobalStyle } from 'styled-components';

import Input from '../new/form/Input';

export const colors = {
  background: '#F3F5F7',
  backgroundSide: '#FFFFFF',
  primary: '#DD0000',
  primaryShade: '#F2DADC', // primary - opacity 0.15
  fontPrimary: '#242429',
  fontSecondary: '#AAAAAA',
  fontTertiary: '#FFFFFF',
  hlavniStrana: '#8E9699',
  aplikace: '#E3B738',
  obchodniSubjekty: '#DF5248',
  obchodniPripady: '#578CB4',
  produkty: '#D96675',
  sklad: '#379482',
  evidence: '#9F7394',
  uzivatele: '#F7995C',
  gray: '#979797',
};

export type ColorTheme =
  | 'primary'
  | 'hlavniStrana'
  | 'aplikace'
  | 'obchodniSubjekty'
  | 'obchodniPripady'
  | 'produkty'
  | 'sklad'
  | 'evidence'
  | 'uzivatele'
  | 'gray';

export const color = (colorTheme?: ColorTheme) => {
  switch (colorTheme) {
    case 'hlavniStrana':
      return colors.hlavniStrana;
    case 'aplikace':
      return colors.aplikace;
    case 'obchodniSubjekty':
      return colors.obchodniSubjekty;
    case 'obchodniPripady':
      return colors.obchodniPripady;
    case 'produkty':
      return colors.produkty;
    case 'sklad':
      return colors.sklad;
    case 'evidence':
      return colors.evidence;
    case 'uzivatele':
      return colors.uzivatele;
    case 'gray':
      return colors.gray;
    default:
      return colors.primary;
  }
};

export const breakpoints = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1280,
  xl: 1920,
};

export const medias = {
  md: `@media (min-width: ${breakpoints.md}px)`,
  lg: `@media (min-width: ${breakpoints.lg}px)`,
  xl: `@media (min-width: ${breakpoints.xl}px)`,
};

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

  html {
    /**
     * @CSS trick to easily count rem from px
     * @see https://www.freecodecamp.org/news/what-is-rem-in-css/
     */
    font-size: 62.5% !important;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    line-height: 160%;
    color: ${colors.fontPrimary};
  }

  * {
    margin: 0;
    padding: 0;

    &::after,
    &::before {
      box-sizing: border-box;
      font-family: 'Open Sans', sans-serif;
      margin: 0;
      padding: 0;
    }
  }

  a {
    text-decoration: none;
    color: ${colors.fontPrimary};
  }

  .ql-editor {
    font-size: 1.4rem;

    img {
      max-width: 150px;
      height: auto;
    }
  }

  .select__search {
    display: flex;
    align-items: center;
    border: 1px solid #e0e2e3;
    border-top-width: 0;
    width: 100%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
    z-index: 100;

    ${Input} {
      border: none;
      border-radius: 0;
      color: rgba(0, 0, 0, 0.7);
      height: 32px;
      font-size: 12px;

      &::placeholder {
        color: #aaaaaa;
      }
    }

    svg {
      position: absolute;
      right: 16px;
      width: 18px;
      height: 18px;
      cursor: pointer;

      path {
        fill: #aaaaaa;
      }

      &:hover {
        path {
          fill: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }

  .select__options {
    width: 100%;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow-y: auto;
    z-index: 100;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);

    .select__option {
      display: flex;
      align-items: center;
      padding: 0 18px;
      height: 36px;
      border-left: 1px solid #e0e2e3;
      border-right: 1px solid #e0e2e3;
      background-color: white;

      &--divider {
        border-bottom: 1px solid #e0e2e3;
      }

      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  &.select--no-search {
    .select__options {
      top: 40px;
    }
  }

  .select__portal {
    position: fixed;
  }

  .image-tooltip-max-width {
    max-width: 350px;
  }
`;

export default GlobalStyle;
