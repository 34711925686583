import { ReactElement, lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const ApplicationsPage = lazy(
  () => import('../pages/applications/ApplicationsPage'),
);
const OSPage = lazy(() => import('../pages/os/OSPage'));
const OPPage = lazy(() => import('../pages/op/OPPage'));
const ProductsPage = lazy(() => import('../pages/product/ProductsPage'));
const EvidencePage = lazy(() => import('../pages/EvidencePage'));
const ProductCategoriesPage = lazy(
  () => import('../pages/product/category/ProductCategoriesPage'),
);
const ProductSubcategoriesPage = lazy(
  () => import('../pages/product/subcategory/ProductSubcategoriesPage'),
);
const ProductCategoryDetailPage = lazy(
  () => import('../pages/product/category/ProductCategoryDetailPage'),
);
const ProductCategoryAddPage = lazy(
  () => import('../pages/product/category/ProductCategoryAddPage'),
);
const ProductCategoryEditPage = lazy(
  () => import('../pages/product/category/ProductCategoryEditPage'),
);
const ProductSubcategoryAddPage = lazy(
  () => import('../pages/product/subcategory/ProductSubcategoryAddPage'),
);
const ProductSubcategoryEditPage = lazy(
  () => import('../pages/product/subcategory/ProductSubcategoryEditPage'),
);
const ProductSubcategoryDetailPage = lazy(
  () => import('../pages/product/subcategory/ProductSubcategoryDetailPage'),
);
const ProductAddPage = lazy(() => import('../pages/product/ProductAddPage'));
const ProductEditPage = lazy(() => import('../pages/product/ProductEditPage'));
const ProductDetailPage = lazy(
  () => import('../pages/product/ProductDetailPage'),
);
const MapAddPage = lazy(() => import('../pages/stock/map/MapAddPage'));
const OSAddPage = lazy(() => import('../pages/os/OSAddPage'));
const OSEditPage = lazy(() => import('../pages/os/OSEditPage'));
const OSDetailPage = lazy(() => import('../pages/os/OSDetailPage'));
const RecipesPage = lazy(() => import('../pages/recipes/RecipesPage'));
const RecipeAddPage = lazy(() => import('../pages/recipes/RecipeAddPage'));
const RecipeTypesPage = lazy(
  () => import('../pages/recipes/recipe-types/RecipeTypesPage'),
);
const RecipeEditPage = lazy(() => import('../pages/recipes/RecipeEditPage'));
const StockItemsPage = lazy(
  () => import('../pages/stock/stock-items/StockItemsPage'),
);
const StockItemAddPage = lazy(
  () => import('../pages/stock/stock-items/StockItemAddPage'),
);
const StockItemEditPage = lazy(
  () => import('../pages/stock/stock-items/StockItemEditPage'),
);
const StockItemDetailPage = lazy(
  () => import('../pages/stock/stock-items/StockItemDetailPage'),
);
const StockDetailPage = lazy(
  () => import('../pages/stock/stock/StockDetailPage'),
);
const StockEditPage = lazy(() => import('../pages/stock/stock/StockEditPage'));
const StockAddPage = lazy(() => import('../pages/stock/stock/StockAddPage'));
const StocksPage = lazy(() => import('../pages/stock/stock/StocksPage'));
const BuildingsPage = lazy(
  () => import('../pages/stock/building/BuildingsPage'),
);
const BuildingAddPage = lazy(
  () => import('../pages/stock/building/BuildingAddPage'),
);
const BuildingEditPage = lazy(
  () => import('../pages/stock/building/BuildingEditPage'),
);
const BuildingDetailPage = lazy(
  () => import('../pages/stock/building/BuildingDetailPage'),
);
const RacksPage = lazy(() => import('../pages/stock/rack/RacksPage'));
const RackAddPage = lazy(() => import('../pages/stock/rack/RackAddPage'));
const RackEditPage = lazy(() => import('../pages/stock/rack/RackEditPage'));
const RackDetailPage = lazy(() => import('../pages/stock/rack/RackDetailPage'));
const RecipesTablePage = lazy(
  () => import('../pages/recipes/RecipesTablePage'),
);
const MapEditPage = lazy(() => import('../pages/stock/map/MapEditPage'));
const MapsPage = lazy(() => import('../pages/stock/map/MapsPage'));
const MapDetailPage = lazy(() => import('../pages/stock/map/MapDetailPage'));
const StockItemCategoriesPage = lazy(
  () => import('../pages/stock/stock-item-category/StockItemCategoriesPage'),
);
const StockItemCategoryAddPage = lazy(
  () => import('../pages/stock/stock-item-category/StockItemCategoryAddPage'),
);
const StockItemCategoryEditPage = lazy(
  () => import('../pages/stock/stock-item-category/StockItemCategoryEditPage'),
);
const StockItemCategoryDetailPage = lazy(
  () =>
    import('../pages/stock/stock-item-category/StockItemCategoryDetailPage'),
);
const RecipeTypeAddPage = lazy(
  () => import('../pages/recipes/recipe-types/RecipeTypeAddPage'),
);
const RecipeTypeEditPage = lazy(
  () => import('../pages/recipes/recipe-types/RecipeTypeEditPage'),
);
const RecipeTypeDetailPage = lazy(
  () => import('../pages/recipes/recipe-types/RecipeTypeDetailPage'),
);
const TestPage = lazy(() => import('../pages/TestPage'));
const StockSettingsPage = lazy(
  () => import('../pages/stock/StockSettingsPage'),
);
const StockMapPage = lazy(() => import('../pages/stock/StockMapPage'));
const OPSettingsPage = lazy(() => import('../pages/op/OPSettingsPage'));
const OPAddPage = lazy(() => import('../pages/op/OPAddPage'));
const UsersPage = lazy(() => import('../pages/user/UsersPage'));
const UserAddPage = lazy(() => import('../pages/user/UserAddPage'));
const UserEditPage = lazy(() => import('../pages/user/UserEditPage'));
const UserDetailPage = lazy(() => import('../pages/user/UserDetailPage'));
const OPDetailPage = lazy(() => import('../pages/op/OPDetailPage'));
const OPEditPage = lazy(() => import('../pages/op/OPEditPage'));
const OPTasksPage = lazy(() => import('../pages/op/OPTasksPage'));
const OPTasksPoptavkaPage = lazy(
  () => import('../pages/op/OPTasksPoptavkaPage'),
);
const ClientDashboardPage = lazy(
  () => import('../pages/client/ClientDashboardPage'),
);
const ClientOPPage = lazy(() => import('../pages/client/ClientOPPage'));
const ClientOPDetailPage = lazy(
  () => import('../pages/client/ClientOPDetailPage'),
);
const FotodokumentaceSeznamPage = lazy(
  () => import('../pages/applications/FotodokumentaceSeznamPage'),
);
const FotodokumentaceNovaPage = lazy(
  () => import('../pages/applications/FotodokumentaceNovaPage'),
);
const FotodokumentaceNovaTypePage = lazy(
  () => import('../pages/applications/FotodokumentaceNovaTypePage'),
);
const FotodokumentaceRozdelanePage = lazy(
  () => import('../pages/applications/FotodokumentaceRozdelanePage'),
);
const FotodokumentacePage = lazy(
  () => import('../pages/applications/FotodokumentacePage'),
);
const RealizacePage = lazy(() => import('../pages/applications/RealizacePage'));
const ProductSettingsPage = lazy(
  () => import('../pages/product/ProductSettingsPage'),
);
const RecipeSettingsPage = lazy(
  () => import('../pages/recipes/RecipeSettingsPage'),
);
const ImportPage = lazy(() => import('../pages/ImportPage'));

export interface Route {
  path: string;
  isPrivate?: boolean;
  element: () => ReactElement;
}

const routes: Route[] = [
  {
    path: '/import',
    isPrivate: true,
    element: () => <ImportPage />,
  },
  {
    path: '/klientska-zona',
    isPrivate: false,
    element: () => <ClientDashboardPage />,
  },
  {
    path: '/klientska-zona/obchodni-pripady',
    isPrivate: false,
    element: () => <ClientOPPage />,
  },
  {
    path: '/klientska-zona/obchodni-pripady/:id',
    isPrivate: false,
    element: () => <ClientOPDetailPage />,
  },
  {
    path: '/test',
    isPrivate: false,
    element: () => <TestPage />,
  },
  {
    path: '/',
    isPrivate: true,
    element: () => <DashboardPage />,
  },
  {
    path: '/aplikace/realizace',
    isPrivate: true,
    element: () => <RealizacePage />,
  },
  {
    path: '/aplikace/realizace/uprava',
    isPrivate: true,
    element: () => <RealizacePage isEditMode />,
  },
  {
    path: '/aplikace',
    isPrivate: true,
    element: () => <ApplicationsPage />,
  },
  {
    path: '/aplikace/fotodokumentace',
    isPrivate: true,
    element: () => <FotodokumentacePage />,
  },
  {
    path: '/aplikace/fotodokumentace/rozdelane',
    isPrivate: true,
    element: () => <FotodokumentaceRozdelanePage />,
  },
  {
    path: '/aplikace/fotodokumentace/nova',
    isPrivate: true,
    element: () => <FotodokumentaceNovaPage />,
  },
  {
    path: '/aplikace/fotodokumentace/nova/:type/:id',
    isPrivate: true,
    element: () => <FotodokumentaceNovaTypePage />,
  },
  {
    path: '/aplikace/fotodokumentace/seznam',
    isPrivate: true,
    element: () => <FotodokumentaceSeznamPage />,
  },
  {
    path: '/obchodni-pripady/nastaveni',
    isPrivate: true,
    element: () => <OPSettingsPage />,
  },
  {
    path: '/obchodni-pripady',
    isPrivate: true,
    element: () => <OPPage />,
  },
  {
    path: '/obchodni-pripady/vytvorit',
    isPrivate: true,
    element: () => <OPAddPage />,
  },
  {
    path: '/obchodni-pripady/:id/upravit',
    isPrivate: true,
    element: () => <OPEditPage />,
  },
  {
    path: '/obchodni-pripady/:id/ukoly',
    isPrivate: true,
    element: () => <OPTasksPage />,
  },
  {
    path: `/obchodni-pripady/:id/ukoly/:taskId/poptavka`,
    isPrivate: true,
    element: () => <OPTasksPoptavkaPage />,
  },
  {
    path: '/obchodni-pripady/:id',
    isPrivate: true,
    element: () => <OPDetailPage />,
  },
  {
    path: '/produkty',
    isPrivate: true,
    element: () => <ProductsPage />,
  },
  {
    path: '/sklad',
    isPrivate: true,
    element: () => <Navigate to="/sklad/skladove-polozky" replace />,
  },
  {
    path: '/evidence',
    isPrivate: true,
    element: () => <EvidencePage />,
  },
  {
    path: '/uzivatele',
    isPrivate: true,
    element: () => <UsersPage />,
  },
  {
    path: '/uzivatele/vytvorit',
    isPrivate: true,
    element: () => <UserAddPage />,
  },
  {
    path: '/uzivatele/:id/upravit',
    isPrivate: true,
    element: () => <UserEditPage />,
  },
  {
    path: '/uzivatele/:id',
    isPrivate: true,
    element: () => <UserDetailPage />,
  },
  {
    path: '/prihlaseni',
    element: () => <LoginPage />,
  },
  {
    path: '/produkty/nastaveni',
    isPrivate: true,
    element: () => <ProductSettingsPage />,
  },
  {
    path: '/produkty/kategorie',
    isPrivate: true,
    element: () => <ProductCategoriesPage />,
  },
  {
    path: '/produkty/kategorie/vytvorit',
    isPrivate: true,
    element: () => <ProductCategoryAddPage />,
  },
  {
    path: '/produkty/kategorie/:id/upravit',
    isPrivate: true,
    element: () => <ProductCategoryEditPage />,
  },
  {
    path: '/produkty/kategorie/:id/smazat',
    isPrivate: true,
    element: () => <ProductCategoriesPage />,
  },
  {
    path: '/produkty/kategorie/:id',
    isPrivate: true,
    element: () => <ProductCategoryDetailPage />,
  },
  {
    path: '/produkty/podkategorie',
    isPrivate: true,
    element: () => <ProductSubcategoriesPage />,
  },
  {
    path: '/produkty/podkategorie/vytvorit',
    isPrivate: true,
    element: () => <ProductSubcategoryAddPage />,
  },
  {
    path: '/produkty/podkategorie/:id/upravit',
    isPrivate: true,
    element: () => <ProductSubcategoryEditPage />,
  },
  {
    path: '/produkty/podkategorie/:id/smazat',
    isPrivate: true,
    element: () => <ProductCategoriesPage />,
  },
  {
    path: '/produkty/podkategorie/:id',
    isPrivate: true,
    element: () => <ProductSubcategoryDetailPage />,
  },
  {
    path: '/produkty/vytvorit',
    isPrivate: true,
    element: () => <ProductAddPage />,
  },
  {
    path: '/produkty/:id/upravit',
    isPrivate: true,
    element: () => <ProductEditPage />,
  },
  {
    path: '/produkty/:id',
    isPrivate: true,
    element: () => <ProductDetailPage />,
  },
  {
    path: '/sklad/mapy',
    isPrivate: true,
    element: () => <MapsPage />,
  },
  {
    path: '/sklad/mapy/vytvorit',
    isPrivate: true,
    element: () => <MapAddPage />,
  },
  {
    path: '/sklad/mapy/:id/upravit',
    isPrivate: true,
    element: () => <MapEditPage />,
  },
  {
    path: '/sklad/mapy/:id',
    isPrivate: true,
    element: () => <MapDetailPage />,
  },
  {
    path: '/obchodni-subjekty',
    isPrivate: true,
    element: () => <OSPage />,
  },
  {
    path: '/obchodni-subjekty/vytvorit',
    isPrivate: true,
    element: () => <OSAddPage />,
  },
  {
    path: '/obchodni-subjekty/:id/upravit',
    isPrivate: true,
    element: () => <OSEditPage />,
  },
  {
    path: '/obchodni-subjekty/:id',
    isPrivate: true,
    element: () => <OSDetailPage />,
  },
  {
    path: '/navody/nastaveni',
    isPrivate: true,
    element: () => <RecipeSettingsPage />,
  },
  {
    path: '/navody/typy/vytvorit',
    isPrivate: true,
    element: () => <RecipeTypeAddPage />,
  },
  {
    path: '/navody/typy/:id/upravit',
    isPrivate: true,
    element: () => <RecipeTypeEditPage />,
  },
  {
    path: '/navody/typy/:id',
    isPrivate: true,
    element: () => <RecipeTypeDetailPage />,
  },
  {
    path: '/navody/typy',
    isPrivate: true,
    element: () => <RecipeTypesPage />,
  },
  {
    path: '/navody/vytvorit',
    isPrivate: true,
    element: () => <RecipeAddPage />,
  },
  {
    path: '/navody/seznam',
    isPrivate: true,
    element: () => <RecipesTablePage />,
  },
  {
    path: '/navody/:id/upravit',
    isPrivate: true,
    element: () => <RecipeEditPage />,
  },
  {
    path: '/navody/*',
    isPrivate: true,
    element: () => <RecipesPage />,
  },
  {
    path: '/sklad/nastaveni',
    isPrivate: true,
    element: () => <StockSettingsPage />,
  },
  {
    path: '/sklad/mapa',
    isPrivate: true,
    element: () => <StockMapPage />,
  },
  {
    path: '/sklad/skladove-polozky/kategorie',
    isPrivate: true,
    element: () => <StockItemCategoriesPage />,
  },
  {
    path: '/sklad/skladove-polozky/kategorie/vytvorit',
    isPrivate: true,
    element: () => <StockItemCategoryAddPage />,
  },
  {
    path: '/sklad/skladove-polozky/kategorie/:id/upravit',
    isPrivate: true,
    element: () => <StockItemCategoryEditPage />,
  },
  {
    path: '/sklad/skladove-polozky/kategorie/:id',
    isPrivate: true,
    element: () => <StockItemCategoryDetailPage />,
  },
  {
    path: '/sklad/skladove-polozky',
    isPrivate: true,
    element: () => <StockItemsPage />,
  },
  {
    path: '/sklad/skladove-polozky/vytvorit',
    isPrivate: true,
    element: () => <StockItemAddPage />,
  },
  {
    path: '/sklad/skladove-polozky/:id/upravit',
    isPrivate: true,
    element: () => <StockItemEditPage />,
  },
  {
    path: '/sklad/skladove-polozky/:id',
    isPrivate: true,
    element: () => <StockItemDetailPage />,
  },
  {
    path: '/sklad/regaly',
    isPrivate: true,
    element: () => <RacksPage />,
  },
  {
    path: '/sklad/regaly/vytvorit',
    isPrivate: true,
    element: () => <RackAddPage />,
  },
  {
    path: '/sklad/regaly/:id/upravit',
    isPrivate: true,
    element: () => <RackEditPage />,
  },
  {
    path: '/sklad/regaly/:id',
    isPrivate: true,
    element: () => <RackDetailPage />,
  },
  {
    path: '/sklad/sklady',
    isPrivate: true,
    element: () => <StocksPage />,
  },
  {
    path: '/sklad/sklady/vytvorit',
    isPrivate: true,
    element: () => <StockAddPage />,
  },
  {
    path: '/sklad/sklady/:id/upravit',
    isPrivate: true,
    element: () => <StockEditPage />,
  },
  {
    path: '/sklad/sklady/:id',
    isPrivate: true,
    element: () => <StockDetailPage />,
  },
  {
    path: '/sklad/budovy',
    isPrivate: true,
    element: () => <BuildingsPage />,
  },
  {
    path: '/sklad/budovy/vytvorit',
    isPrivate: true,
    element: () => <BuildingAddPage />,
  },
  {
    path: '/sklad/budovy/:id/upravit',
    isPrivate: true,
    element: () => <BuildingEditPage />,
  },
  {
    path: '/sklad/budovy/:id',
    isPrivate: true,
    element: () => <BuildingDetailPage />,
  },
];

export default routes;
