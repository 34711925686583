import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { SettingResourceCollectionType } from '../api/Api';
import SwaggerApi from '../api/SwaggerApi';
import { useAppDispatch, useAppSelector } from '../config/store';
import { setPermissions } from '../slices/AuthSlice';
import { setSettings } from '../slices/SettingsSlice';

const Base: FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  useQuery<unknown, unknown, SettingResourceCollectionType>({
    queryKey: ['settings'],
    queryFn: () => SwaggerApi.settings.getSettings(),
    onSuccess: (data) => {
      dispatch(setSettings(data.data));
    },
    enabled: state.auth.isAuthenticated,
  });
  useQuery({
    queryKey: ['permissions'],
    queryFn: () => SwaggerApi.auth.getPermissions(),
    onSuccess: (data) => {
      dispatch(setPermissions(data.data.permissions));
    },
    enabled: state.auth.isAuthenticated,
  });
  return null;
};

export default Base;
