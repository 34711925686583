import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export interface ErrorType {
  title: ReactNode;
  description: ReactNode | ReactNode[];
}

export interface ErrorState {
  queue: ErrorType[];
}

const initialState: ErrorState = {
  queue: [],
};

const ErrorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    addError: (state, { payload }: PayloadAction<ErrorType>) => {
      state.queue = [...state.queue, payload];
    },
    removeError: (state) => {
      state.queue = state.queue.slice(1);
    },
  },
});

export const { addError, removeError } = ErrorSlice.actions;

export default ErrorSlice.reducer;
