import CloseIcon from '@mui/icons-material/Close';
import { Result } from '@zxing/library';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { useZxing } from 'react-zxing';
import styled from 'styled-components';

import IconButton from '../new/form/IconButton';

interface QrAndBarCodeReaderProviderProps extends PropsWithChildren<any> {}

interface QrAndBarCodeReaderProviderState {
  enabled: boolean;
  onDecode?: (result: Result) => void;
  onError?: (error: unknown) => void;
  setState: (value: Omit<QrAndBarCodeReaderProviderState, 'setState'>) => void;
}

const initialState: QrAndBarCodeReaderProviderState = {
  enabled: false,
  onDecode: undefined,
  onError: undefined,
  setState: () => null,
};

const QrAndBarCodeReaderProviderContext =
  createContext<QrAndBarCodeReaderProviderState>(initialState);

export const QrAndBarCodeReaderProvider: FC<
  QrAndBarCodeReaderProviderProps
> = ({ children }) => {
  const [state, setState] =
    useState<Omit<QrAndBarCodeReaderProviderState, 'setState'>>(initialState);

  const { ref } = useZxing({
    paused: !state.enabled,
    onError: state.onError,
    onDecodeResult: state.onDecode,
    constraints: {
      video: {
        facingMode: 'environment',
      },
    },
  });

  return (
    <QrAndBarCodeReaderProviderContext.Provider
      value={{
        ...state,
        setState,
      }}
    >
      {children}
      {state.enabled && (
        <StyledQrAndBarCodeReaderProviderVideoWrapper>
          <video ref={ref} />
          <IconButton
            onClick={() => {
              setState({
                enabled: false,
                onDecode: undefined,
                onError: undefined,
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledQrAndBarCodeReaderProviderVideoWrapper>
      )}
    </QrAndBarCodeReaderProviderContext.Provider>
  );
};

const StyledQrAndBarCodeReaderProviderVideoWrapper = styled.div`
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;

  video {
    width: 100%;
    height: 100%;
  }

  ${IconButton} {
    position: fixed;
    z-index: 10001;
    top: 2rem;
    right: 2rem;
    width: 8rem;
    height: 8rem;
    display: flex;

    svg {
      width: 5rem;
      height: 5rem;

      path {
        fill: white;
      }
    }
  }
`;

export const useQrAndBarCodeReader = () => {
  const context = useContext(QrAndBarCodeReaderProviderContext);

  if (context === undefined) {
    throw new Error(
      'useQrAndBarCodeReader must be used within a QrAndBarCodeReaderProvider',
    );
  }

  return {
    ...context,
    startReading: (
      onDecode: (result: Result) => void,
      onError?: (error: unknown) => void,
    ) => {
      context.setState({
        enabled: true,
        onDecode,
        onError,
      });
    },
    stopReading: () => {
      context.setState({
        enabled: false,
        onDecode: undefined,
        onError: undefined,
      });
    },
  };
};
