import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { FC, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { store } from '../config/store';
import Backdrop from '../new/Backdrop';
import Errors from '../new/Errors';
import { QrAndBarCodeReaderProvider } from '../providers/QrAndBarCodeReaderProvider';
import GlobalStyle from '../styles/global';
import { defaultTheme } from '../styles/themes';
import Base from './Base';
import Router from './router/Router';
import Toasts from './toasts/Toasts';
import Version from './version/Version';

const App: FC = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: 'always',
            refetchOnWindowFocus: false,
            cacheTime: 0,
          },
          mutations: {
            networkMode: 'always',
          },
        },
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      {/*@ts-ignore*/}
      <ThemeProvider theme={defaultTheme}>
        {/*@ts-ignore*/}
        <GlobalStyle />
        <IntlProvider locale="cs" defaultLocale="cs">
          {/*@ts-ignore*/}
          <Provider store={store}>
            <QrAndBarCodeReaderProvider>
              <Base />
              <Router />
              <Toasts />
              <Errors />
              <Version />
              <Backdrop id="backdrop" />
            </QrAndBarCodeReaderProvider>
          </Provider>
        </IntlProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
