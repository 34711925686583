import classNames, { Argument } from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import './modal.scss';

export interface ModalProps {
  isOpen: boolean;
  close(): void;
  className?: Argument;
  closeForce?: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  close,
  className,
  closeForce = false,
  children,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const backdrop = useRef<HTMLDivElement>(null);

  const onClose = () => {
    document.body.classList.remove('modal-open');
    close();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  useEffect(() => {
    const { current } = backdrop;
    const transitionEnd = () => setActive(isOpen);
    const keyHandler = (e: KeyboardEvent) => e.key === 'Escape' && onClose();
    const clickHandler = (e: Event) => e.target === current && onClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      if (!closeForce) {
        current.addEventListener('click', clickHandler);
      }
      window.addEventListener('keyup', keyHandler);
    }

    if (isOpen) {
      window.setTimeout(() => {
        setActive(isOpen);
        //document.getElementById('root')?.setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        if (!closeForce) {
          current.removeEventListener('click', clickHandler);
        }
      }

      // document.getElementById('root')?.removeAttribute('inert');
      window.removeEventListener('keyup', keyHandler);
    };
  }, [isOpen, close]);

  if (!isOpen && !active) {
    return null;
  }

  return createPortal(
    <div
      {...props}
      ref={backdrop}
      className={classNames(className, 'modal', {
        'modal--active': active && isOpen,
      })}
    >
      <div className="modal__content">
        <div onClick={onClose} className="modal__content__close">
          <i />
        </div>
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default styled(Modal)`
  .modal__content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    min-height: 180px;
  }
`;
