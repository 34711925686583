/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';

export interface LoginInputType {
  /** Email */
  email: string;
  /** Password */
  password: string;
}

export interface UserSettingsItemType {
  /** Key */
  key: string;
  /** Value */
  value?: string;
}

export interface UserSettingsInputType {
  /** Items */
  items: UserSettingsItemType[];
}

export interface PermissionsResourceType {
  /** Email */
  permissions: string[];
}

export interface BranchInputType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Street */
  street: string;
  /** City */
  city: string;
  /** ZIP */
  zip: string;
  /** County */
  county?: string;
  /** Country */
  country: string;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** OS ID */
  os_id: string;
  /** Note */
  note?: string;
  /** Is main? */
  main: boolean;
}

export interface BuildingInputType {
  /** Name */
  name: string;
  /** Map ID */
  map_id?: string;
}

export interface ContactPersonInputType {
  /** ID */
  id: string;
  /** OS ID */
  os_id: string;
  /** Branch ID */
  branch_id?: string;
  /** Name */
  name: string;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** Position */
  position: string;
  /** Note */
  note?: string;
  /** Is main? */
  main: boolean;
  /** Order? */
  orders?: boolean;
  /** Servis? */
  servis?: boolean;
}

export interface PaginationMetaLinkType {
  url?: string;
  label: string;
  active: boolean;
}

export interface PaginationMetaType {
  /** Current Page */
  current_page: number;
  /** From */
  from: number;
  /** Last Page */
  last_page: number;
  /** Links */
  links: PaginationMetaLinkType[];
  /** Path */
  path: string;
  /** Per Page */
  per_page: number;
  /** To */
  to: number;
  /** Total */
  total: number;
}

export interface PaginationLinksType {
  first?: string;
  last?: string;
  prev?: string;
  next?: string;
}

export interface DirectoryAndFileInputType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Path */
  path: string;
  /** Version */
  version: number;
  /** Type */
  type: DirectoryAndFileInputTypeType;
  /** File ID */
  file_id?: string;
  /** Link */
  link?: string;
}

export interface FotodokumentaceInputType {
  /** Custom OS Name */
  os_name?: string;
  /** OS ID */
  os_id?: string;
  /** Custom Contact Person Name */
  contact_person_name?: string;
  /** Contact Person ID */
  contact_person_id?: string;
  /** Misto realizace */
  misto_realizace?: string;
  /** Product Category ID */
  product_category_id?: string;
  /** Product Subcategory ID */
  product_subcategory_id?: string;
  /** Custom Product Name */
  product_name?: string;
  /** Product ID */
  product_id?: string;
  /** Description */
  description?: string;
  /** What */
  what?: string;
  /** Where */
  where?: string;
  /** Category */
  category: FotodokumentaceInputCategoryType;
  /** Data */
  data?: string;
  /** More Files */
  more_files?: string;
  /** Sent At */
  sent_at?: string;
}

export interface GoogleApiPlaceResourceType {
  /** Status */
  status: GoogleApiPlaceResourceStatusType;
  /** Predictions */
  predictions: GoogleApiPlacePredictionType[];
}

export interface GoogleApiPlacePredictionType {
  /** Description */
  description: string;
  /** Place ID */
  place_id?: string;
}

export interface GoogleApiPlaceDetailType {
  /** Status */
  status: GoogleApiPlaceDetailStatusType;
  result: GoogleApiPlaceDetailResultType;
}

export interface GoogleApiPlaceDetailResultType {
  /** Address Components */
  address_components: GoogleApiPlaceDetailResultAddressComponentType[];
}

export interface GoogleApiPlaceDetailResultAddressComponentType {
  /** Long name */
  long_name: string;
  /** Short name */
  short_name: string;
  /** Types */
  types: GoogleApiPlaceDetailResultAddressComponentTypesType[];
}

export interface MapInputType {
  /** ID */
  id?: string;
  /** Name */
  name: string;
  /** Type */
  type: MapInputTypeType;
  /**
   * Width
   * @format int
   */
  width: number;
  /**
   * Height
   * @format int
   */
  height: number;
  /** Objects */
  objects: string;
  /** Background Image ID */
  background_image_id?: string;
  /** Building ID */
  building_id?: string;
  /** Stock ID */
  stock_id?: string;
  /** Rack ID */
  rack_id?: string;
  /** Note */
  note?: string;
}

export interface OPNoteInputType {
  /** Note */
  note?: string;
}

export interface OPInputType {
  /** Shortcut */
  shortcut?: string;
  /** Type */
  type: OpInputTypeType;
  /**
   * Cena pro zakaznika
   * @format float
   */
  cena_pro_zakaznika?: number;
  /**
   * Zaplacena zaloha
   * @format float
   */
  zaplacena_zaloha?: number;
  /**
   * K doplaceni
   * @format float
   */
  k_doplaceni?: number;
  /**
   * Naklady
   * @format float
   */
  naklady?: number;
  /**
   * Zisk
   * @format float
   */
  zisk?: number;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  /**
   * Datum vyhotoveni
   * @format date
   */
  datum_vyhotoveni?: string;
  /** Platnost nabidky */
  platnost_nabidky?: number;
  /**
   * Termin
   * @format date
   */
  termin?: string;
  /** OS Id */
  os_id: string;
  /** Branch Id */
  branch_id: string;
  /** Contact Persons */
  contact_persons?: string[];
  /** Main Contact Person ID */
  main_contact_person_id?: string;
  /** Note */
  note?: string;
}

export interface OPRowInputType {
  /** Type */
  type: OpRowInputTypeType;
  /** Amount */
  amount: number;
  /** OS */
  product_id?: string;
  /** OP */
  op_id: string;
  /** OS */
  os_id: string;
  /** Branch */
  branch_id: string;
  /** Contact Persons */
  contact_persons?: string[];
  /** Main Contact Person ID */
  main_contact_person_id: string;
  /** Is Active */
  is_active?: boolean;
}

export interface OSInputType {
  /** Type */
  type: OsInputTypeType;
  /** Categories */
  categories: OsInputCategoriesType[];
  /** Subcategories */
  subcategories: OsInputSubcategoriesType[];
  /** Note */
  note?: string;
  /** ICO */
  ico?: string;
  /** DIC */
  dic?: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  /** Street */
  street: string;
  /** City */
  city: string;
  /** ZIP */
  zip: string;
  /** County */
  county?: string;
  /** Country */
  country: string;
  /** Delivery Street */
  delivery_street?: string;
  /** Delivery City */
  delivery_city?: string;
  /** Delivery ZIP */
  delivery_zip?: string;
  /** Delivery County */
  delivery_county?: string;
  /** Delivery Country */
  delivery_country?: string;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** Web */
  web?: string;
  /** Confirm */
  confirm?: boolean;
  /**
   * Due date
   * @format int
   */
  due_day?: number;
  /** DPH */
  dph: boolean;
}

export interface ProductCategoryInputType {
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
}

export interface ProductInputType {
  /** OS ID */
  os_id: string;
  /** Branch ID */
  branch_id: string;
  /** Product Category ID */
  product_category_id: string;
  /** Product Subcategory ID */
  product_subcategory_id: string;
  /** Name */
  name: string;
  /**
   * Name
   * @format date
   */
  predano_at?: string;
  /**
   * Name
   * @format date
   */
  vyrazeni_at?: string;
  /** Technicke parametry */
  technicke_parametry?: string;
  /** Fotodokumentace */
  fotodokumentace?: string;
  /** Next */
  next?: string;
}

export interface ProductSubcategoryInputType {
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  /** Shortcut */
  background?: string;
  /** Shortcut */
  color?: string;
  /** Technicke parametry */
  technicke_parametry?: string;
  /** Fotodokumentace */
  fotodokumentace?: string;
  /** Next */
  next?: string;
  /** Product Category ID */
  product_category_id: string;
  /** Description */
  description?: string;
  /** Duration */
  duration?: number;
  /** Suppliers */
  suppliers?: string[];
  /** Main Suppliers ID */
  main_supplier_id?: string;
}

export interface RackInputType {
  /** Name */
  name: string;
  /** Building ID */
  building_id: string;
  /** Stock ID */
  stock_id: string;
  /** Map ID */
  map_id?: string;
}

export interface SettingItemType {
  /** Key */
  key: string;
  /** Group */
  group: string;
  /** Value */
  value: string;
}

export interface SettingInputType {
  /** Items */
  items: SettingItemType[];
}

export interface StockInputType {
  /** Name */
  name: string;
  /** Building ID */
  building_id: string;
  /** Map ID */
  map_id?: string;
}

export interface StockItemCategoryInputType {
  /** Name */
  name: string;
  /** Referral ID */
  referral_id: string;
  /**
   * Level
   * @format int
   */
  level: number;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  os: string[];
  /** Is marze manual? */
  is_marze_manual: boolean;
  /** Is work time manual? */
  is_work_time_manual: boolean;
  /**
   * Work time
   * @format float
   */
  work_time?: number;
}

export interface StockItemProductCategoryInputType {
  /** Product category ID */
  product_category_id: string;
  /** Product subcategory ID */
  product_subcategory_id: string;
}

export interface StockItemOSInputType {
  /** OS ID */
  os_id: string;
  /** Is main? */
  is_main: boolean;
  /** Is manual? */
  is_manual: boolean;
  /**
   * Informative price
   * @format float
   */
  informative_price?: number;
  /** Informative price type */
  informative_price_type?: StockItemOsInputInformativePriceTypeType;
  /** Order to stock? */
  order_to_stock: boolean;
  /**
   * Order amount
   * @format float
   */
  order_amount?: number;
  /** Order amount type */
  order_amount_type?: StockItemOsInputOrderAmountTypeType;
  /** URL */
  url?: string;
}

export interface StockItemRelatedItemInputType {
  /** Stock item ID */
  stock_item_id: string;
  /** Description */
  description?: string;
  /** Type */
  type: StockItemRelatedItemInputTypeType;
}

export interface StockItemLinkInputType {
  /** Path */
  path: string;
  /** Type */
  type: StockItemLinkInputTypeType;
}

export interface StockItemPositionInputType {
  /** ID */
  id: string;
  /** Is main? */
  main: boolean;
  /** Notify on show? */
  notify_on_show: boolean;
  /** Building ID */
  building_id: string;
  /** Stock ID */
  stock_id: string;
  /** Rack ID */
  rack_id: string;
  /** Rack Position Row */
  rack_position_row: number;
  /** Rack Position Column */
  rack_position_column: number;
  /** Rack Position Part Row */
  rack_position_part_row?: number;
  /** Rack Position Part Column */
  rack_position_part_column?: number;
  /** Rack Position Part Index */
  rack_position_part_index?: number;
}

export interface StockItemInputType {
  /** Name */
  name: string;
  /** Alternative name */
  alternative_name?: string;
  /** Description */
  description?: string;
  /** Image ID */
  image_id?: string;
  /** Note */
  note?: string;
  /** Type */
  type: StockItemInputTypeType;
  /** Quantity */
  quantity?: number;
  /** Limit */
  limit: number;
  /** Stock Item Category 0 */
  stock_item_category_id_level_0?: string;
  /** Stock Item Category 1 */
  stock_item_category_id_level_1?: string;
  /** Stock Item Category 2 */
  stock_item_category_id_level_2?: string;
  /** Stock Item Category 3 */
  stock_item_category_id_level_3?: string;
  /** Product categories */
  product_categories: StockItemProductCategoryInputType[];
  /** OS */
  os: StockItemOSInputType[];
  /** Accountancy */
  accountancy: boolean;
  /**
   * Purchase Price
   * @format float
   */
  purchase_price?: number;
  /**
   * Sell Price
   * @format float
   */
  sell_price?: number;
  /**
   * Sell Price with VAP
   * @format float
   */
  sell_price_with_vat?: number;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  /** Is marze manual? */
  is_marze_manual?: boolean;
  /** Is included work price? */
  is_included_work_price?: boolean;
  /**
   * Work price
   * @format float
   */
  work_time?: number;
  /** Is marze manual? */
  is_work_time_manual?: boolean;
  /** Weight */
  weight?: string;
  /** EAN */
  ean?: string;
  /** Related items */
  related_items: StockItemRelatedItemInputType[];
  /** Links */
  links?: StockItemLinkInputType[];
  /** Positions */
  positions: StockItemPositionInputType[];
  /** Is completed? */
  is_completed: boolean;
}

export interface TaskNoteInputType {
  /** Note */
  note?: string;
}

export interface TaskInputType {
  /** OP ID */
  op_id: string;
  /** OP Rows */
  op_rows?: string[];
  /** Type */
  type: TaskInputTypeType;
  /** Name */
  name?: string;
  /** OS ID */
  os_id?: string;
  /** Contact person ID */
  contact_person_id?: string;
  /** Status */
  status: TaskInputStatusType;
  /**
   * Due date
   * @format date
   */
  due_date?: string;
  /** Before realization */
  before_realization?: boolean;
  /** Assigned User ID */
  assigned_user_id?: string;
  /** Note */
  note?: string;
}

export interface UserInputType {
  /** Name */
  name: string;
  /** Email */
  email: string;
  /** Password */
  password?: string;
  /** Permissions */
  permissions: string[];
  /** Is Active */
  is_active: boolean;
  /** First Name */
  first_name?: string;
  /** Last Name */
  last_name?: string;
  /** Phone */
  phone?: string;
  /** Profile Photo Id */
  profile_photo_id?: string;
}

export interface AddressResourceType {
  /** ID */
  id: string;
  /** Street */
  street: string;
  /** City */
  city: string;
  /** ZIP */
  zip: string;
  /** County */
  county?: string;
  /** Country */
  country: string;
  /** Km */
  km?: number;
}

export interface AddressResourceCollectionType {
  /** Data */
  data: AddressResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface BranchResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  address: AddressResourceType;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** Is main? */
  main?: boolean;
  /** Note */
  note?: string;
}

export interface BranchResourceCollectionType {
  /** Data */
  data: BranchResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface BuildingPreviewResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  map?: MapPreviewResourceType;
}

export interface BuildingResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  map?: MapResourceType;
}

export interface BuildingResourceCollectionType {
  /** Data */
  data: BuildingResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface BuildingSelectResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
}

export interface ContactPersonResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** Position */
  position: string;
  /** Branch ID */
  branch_id?: string;
  /** Note */
  note?: string;
  /** Is main? */
  main?: boolean;
  /** Order? */
  orders?: boolean;
  /** Servis? */
  servis?: boolean;
}

export interface ContactPersonResourceCollectionType {
  /** Data */
  data: ContactPersonResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface DirectoryAndFileResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Path */
  path: string;
  /**
   * Version
   * @format int
   */
  version: number;
  /** Referral ID */
  referral_id?: string;
  /** Type */
  type: DirectoryAndFileResourceTypeType;
  file?: FileResourceType;
  /** Link */
  link?: string;
  link_file?: DirectoryAndFileResourceType;
}

export interface DirectoryAndFileResourceCollectionType {
  /** Data */
  data: DirectoryAndFileResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface FilePreviewResourceType {
  /** ID */
  id: string;
  /** URL */
  url: string;
  /** name */
  name: string;
}

export interface FileResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** MIME Type */
  mime?: string;
  /** URL */
  url: string;
  /** Type */
  type: FileResourceTypeType;
}

export interface FileResourceCollectionType {
  /** Data */
  data: FileResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface FotodokumentaceResourceType {
  /** ID */
  id: string;
  /** Custom OS Name */
  os_name?: string;
  os?: OSResourceType;
  /** Custom Contact Person Name */
  contact_person_name?: string;
  contact_person?: ContactPersonResourceType;
  /** Misto realizace */
  misto_realizace?: string;
  product_category?: ProductCategoryResourceType;
  product_subcategory?: ProductSubcategoryResourceType;
  /** Custom Product Name */
  product_name?: string;
  product?: ProductResourceType;
  /** Description */
  description?: string;
  /** What */
  what?: string;
  /** Where */
  where?: string;
  /** Category */
  category: FotodokumentaceResourceCategoryType;
  /** Data */
  data?: FormFieldResourceType[];
  /** More Files */
  more_files?: FormFieldMoreFilesResourceType[];
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at?: number;
  /** Sent at */
  sent_at?: number;
  created_by: UserResourceType;
  zip?: FileResourceType;
}

export interface FotodokumentaceResourceCollectionType {
  /** Data */
  data: FotodokumentaceResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface MapPreviewResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
}

export interface MapResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Type */
  type: MapResourceTypeType;
  /**
   * Width
   * @format int
   */
  width: number;
  /**
   * Height
   * @format int
   */
  height: number;
  /** Objects */
  objects: MapObjectType[];
  /** Rows */
  rows: MapRowType[];
  background_image?: FileResourceType;
  building?: BuildingResourceType;
  stock?: StockResourceType;
  rack?: RackResourceType;
  /** Note */
  note?: string;
}

export interface MapObjectCoordinatesType {
  /**
   * X
   * @format float
   */
  x: number;
  /**
   * Y
   * @format float
   */
  y: number;
}

export interface MapObjectType {
  /** ID */
  id: string;
  /** Color */
  color: string;
  /** Background Color */
  background: MapObjectBackgroundType;
  /**
   * Background Opacity
   * @format float
   */
  opacity: number;
  /** Coordinates */
  coordinates: MapObjectCoordinatesType[];
  /** Link */
  link: string;
  /** Link Type */
  linkType: MapObjectLinkTypeType;
  /** Name */
  name?: boolean;
  /** Is Editable */
  isEditable: boolean;
  /** Is Valid */
  isValid: boolean;
}

export interface MapColumnType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Link */
  link: string;
  /** Link Type */
  linkType: MapColumnLinkTypeType;
  /** Link Value */
  linkValue?: string;
  /**
   * Width
   * @format int
   */
  width: number;
  /** Color */
  color?: string;
  /** Background Color */
  background?: string;
  /** Parts */
  parts?: MapRowType[];
}

export interface MapRowType {
  /** Columns */
  columns: MapColumnType[];
  /**
   * Height
   * @format float
   */
  height: number;
  /** Color */
  color: string;
  /** Background Color */
  background: string;
}

export interface MapResourceCollectionType {
  /** Data */
  data: MapResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface MeResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Email */
  email: string;
  /** Permissions */
  permissions: string[];
  /** User Settings */
  settings?: UserSettingResourceType[];
  tokens?: TokensResourceType;
  /** Is Active */
  is_active?: boolean;
  /** First Name */
  first_name?: string;
  /** Last Name */
  last_name?: string;
  /** Phone */
  phone?: string;
  profile_photo?: FileResourceType;
}

export interface TokensResourceType {
  /** Access Token */
  access_token: string;
}

export interface OPResourceType {
  /** ID */
  id: string;
  /** Name */
  name?: string;
  /** Shortcut */
  shortcut?: string;
  /** Type */
  type?: OpResourceTypeType;
  /**
   * Cena pro zakaznika
   * @format float
   */
  cena_pro_zakaznika?: number;
  /**
   * Zaplacena zaloha
   * @format float
   */
  zaplacena_zaloha?: number;
  /**
   * K doplaceni
   * @format float
   */
  k_doplaceni?: number;
  /**
   * Naklady
   * @format float
   */
  naklady?: number;
  /**
   * Zisk
   * @format float
   */
  zisk?: number;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  /** Datum vyhotoveni */
  datum_vyhotoveni?: number;
  /** Platnost nabidky */
  platnost_nabidky?: number;
  /** Termin */
  termin?: number;
  os: OSResourceType;
  branch: BranchResourceType;
  /** Created at */
  created_at: number;
  /** OP Row Contact Person */
  op_contact_persons?: OPRowContactPersonResourceType[];
  /** Note */
  note?: string;
  /** OP Rows */
  op_rows?: OPRowResourceType[];
}

export interface OPResourceCollectionType {
  /** Data */
  data: OPResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface OPRowContactPersonResourceType {
  op_row?: OPRowResourceType;
  op?: OPResourceType;
  contact_person?: ContactPersonResourceType;
  /** Main */
  main: boolean;
}

export interface OPRowContactPersonResourceCollectionType {
  /** Data */
  data: OPRowContactPersonResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface OPRowResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Type */
  type?: OpRowResourceTypeType;
  /** Amount */
  amount: number;
  product: ProductResourceType;
  op: OPResourceType;
  os: OSResourceType;
  branch: BranchResourceType;
  /** Created at */
  created_at?: number;
  /** OP Row Contact Person */
  op_contact_persons?: OPRowContactPersonResourceType[];
  /** State */
  state?: string;
  /** Is Active */
  is_active?: boolean;
}

export interface OPRowResourceCollectionType {
  /** Data */
  data: OPRowResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface OPTaskResourceType {
  op_row?: OPRowResourceType;
  op?: OPResourceType;
  task: TaskResourceType;
}

export interface OPTaskResourceCollectionType {
  /** Data */
  data: OPTaskResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface OSPreviewResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
}

export interface OSResourceType {
  /** ID */
  id: string;
  /** Type */
  type: OsResourceTypeType;
  /** Categories */
  categories: OsResourceCategoriesType[];
  /** Subcategories */
  subcategories: OsResourceSubcategoriesType[];
  /** ICO */
  ico?: string;
  /** DIC */
  dic?: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  address: AddressResourceType;
  delivery_address?: AddressResourceType;
  /** Phone */
  phone?: string;
  /** Email */
  email?: string;
  /** Web */
  web?: string;
  /** Confirm */
  confirm: boolean;
  /**
   * Due date
   * @format int
   */
  due_day?: number;
  /** Note */
  note?: string;
  /** Branches */
  branches?: BranchResourceType[];
  /** Contact Persons */
  contactPersons?: ContactPersonResourceType[];
  /** DPH */
  dph: boolean;
}

export interface OSResourceCollectionType {
  /** Data */
  data: OSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface ProductCategoryResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  /** Product Subcategory Without Category */
  product_subcategories: ProductSubcategoryResourceType[];
}

export interface ProductCategoryResourceCollectionType {
  /** Data */
  data: ProductCategoryResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface ProductResourceType {
  /** ID */
  id: string;
  os: OSResourceType;
  product_category: ProductCategoryResourceType;
  product_subcategory: ProductSubcategoryResourceType;
  branch: BranchResourceType;
  /** Name */
  name: string;
  /** Predano */
  predano_at?: number;
  /** Vyrazeno */
  vyrazeni_at?: number;
  /** Technicke parametry */
  technicke_parametry?: FormFieldResourceType[];
  /** Fotodokumentace */
  fotodokumentace?: FormFieldResourceType[];
  /** Next */
  next?: FormFieldResourceType[];
}

export interface ProductResourceCollectionType {
  /** Data */
  data: ProductResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface ProductSubcategoryOSResourceType {
  product_subcategory?: ProductSubcategoryResourceType;
  os?: OSResourceType;
  /** Main */
  main: boolean;
}

export interface ProductSubcategoryOSResourceCollectionType {
  /** Data */
  data: ProductSubcategoryOSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface ProductSubcategoryResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  /** Background Color */
  background?: string;
  /** Color */
  color?: string;
  /** Technicke parametry */
  technicke_parametry?: FormFieldResourceType[];
  /** Fotodokumentace */
  fotodokumentace?: FormFieldResourceType[];
  /** Next */
  next?: FormFieldResourceType[];
  product_category: ProductCategoryResourceType;
  /** Description */
  description?: string;
  /** Duration */
  duration?: number;
  /** Suppliers */
  suppliers?: ProductSubcategoryOSResourceType[];
}

export interface FormFieldItemResourceType {
  /** ID */
  id: string;
  /** Value */
  value: string;
}

export interface FormFieldConditionResourceType {
  /** ID */
  id: string;
  /** Field */
  field: string;
  /** Operator */
  operator: FormFieldConditionResourceOperatorType;
  /** Value */
  value: string[];
}

export interface FormFieldValidationResourceType {
  /** Is Required */
  isRequired?: boolean;
  /** Min */
  min?: number;
  /** Max */
  max?: number;
}

export interface FormFieldMoreFilesResourceType {
  /** ID */
  id: string;
  /** Value */
  value: string;
  /** File ID */
  fileId: string;
}

export interface FormFieldResourceType {
  /** ID */
  id: string;
  /** Type */
  type: FormFieldResourceTypeType;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** i */
  i?: string;
  /** Items */
  items: FormFieldItemResourceType[];
  /** Is Editable */
  isEditable: boolean;
  /** Has Video */
  hasVideo?: boolean;
  /** Has More Files */
  hasMoreFiles?: boolean;
  /** Conditions */
  conditions: FormFieldConditionResourceType[];
  /** Value */
  value?: string;
  /** File ID */
  fileId?: string;
  /** Error */
  error?: boolean;
  validation?: FormFieldValidationResourceType;
  /** More Files */
  more_files?: FormFieldMoreFilesResourceType[];
}

export interface ProductSubcategoryResourceCollectionType {
  /** Data */
  data: ProductSubcategoryResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface RackPreviewResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  map?: MapPreviewResourceType;
}

export interface RackResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  building: BuildingPreviewResourceType;
  stock: StockPreviewResourceType;
  map?: MapResourceType;
}

export interface RackResourceCollectionType {
  /** Data */
  data: RackResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface RecipeCommentResourceType {
  /** ID */
  id: string;
  /** Comment */
  comment?: string;
  /** Type */
  type?: RecipeCommentResourceTypeType;
  created_by?: UserResourceType;
  /**
   * Created At
   * @format int
   */
  created_at?: number;
  /**
   * Updated At
   * @format int
   */
  updated_at?: number;
}

export interface RecipeCommentResourceCollectionType {
  /** Data */
  data: RecipeCommentResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface RecipeResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Path */
  path: string;
  /**
   * Version
   * @format int
   */
  version: number;
  /**
   * Step
   * @format int
   */
  step?: number;
  /**
   * Steps
   * @format int
   */
  steps?: number;
  /** Type */
  type: string;
  file?: FileResourceType;
  recipe_type?: RecipeTypeResourceType;
  /** Comments */
  comments?: RecipeCommentResourceType[];
  /** Referral ID */
  referral_id?: string;
  prev?: FileResourceType;
  next?: FileResourceType;
}

export interface RecipeResourceCollectionType {
  /** Data */
  data: RecipeResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface RecipeTypeResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /**
   * Version
   * @format int
   */
  version: number;
  /** Mini */
  mini: boolean;
  file: FileResourceType;
  /** Referral ID */
  referral_id: string;
}

export interface RecipeTypeResourceCollectionType {
  /** Data */
  data: RecipeTypeResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface SettingResourceType {
  /** ID */
  id: string;
  /** Key */
  key: string;
  /** Group */
  group?: string;
  /** Value */
  value?: string;
}

export interface SettingResourceCollectionType {
  /** Data */
  data: SettingResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemCategoryOSOSResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
  /** Stock items count */
  stock_items_count: number;
}

export interface StockItemCategoryOSOSResourceCollectionType {
  /** Data */
  data: StockItemCategoryOSOSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemCategoryOSResourceType {
  /** ID */
  id: string;
  os: StockItemCategoryOSOSResourceType;
  /** Is main? */
  is_main: boolean;
  /** Is manual? */
  is_manual: boolean;
  /** Order */
  order: number;
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at: number;
}

export interface StockItemCategoryOSResourceCollectionType {
  /** Data */
  data: StockItemCategoryOSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemCategoryResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  referral?: StockItemCategoryResourceType;
  /** Level */
  level: number;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  /** OS */
  os?: StockItemCategoryOSResourceType[];
  /** Is marze manual? */
  is_marze_manual: boolean;
  /** Is work time manual? */
  is_work_time_manual: boolean;
  /**
   * Work time
   * @format float
   */
  work_time?: number;
  /** Main OS */
  main_os?: string;
  /** Other OS */
  other_os?: string;
  /** Referral catgories */
  referral_categories?: string;
}

export interface StockItemLinkRecipeResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Path */
  path: string;
}

export interface StockItemLinkRecipeResourceCollectionType {
  /** Data */
  data: StockItemLinkRecipeResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemLinkResourceType {
  /** ID */
  id: string;
  /** Path */
  path: string;
  /** Type */
  type: StockItemLinkResourceTypeType;
  /** Order */
  order: number;
  recipe?: StockItemLinkRecipeResourceType;
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at: number;
}

export interface StockItemLinkResourceCollectionType {
  /** Data */
  data: StockItemLinkResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemOSOSResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
}

export interface StockItemOSOSResourceCollectionType {
  /** Data */
  data: StockItemOSOSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemOSResourceType {
  /** ID */
  id: string;
  os: StockItemOSOSResourceType;
  /** Is main? */
  is_main: boolean;
  /** Is manual? */
  is_manual: boolean;
  /** Order */
  order: number;
  /**
   * Informative price
   * @format float
   */
  informative_price?: number;
  /** Informative price type */
  informative_price_type?: StockItemOsResourceInformativePriceTypeType;
  /** Order to stock? */
  order_to_stock: boolean;
  /**
   * Order amount
   * @format float
   */
  order_amount?: number;
  /** Order amount type */
  order_amount_type?: StockItemOsResourceOrderAmountTypeType;
  /** URL */
  url?: string;
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at: number;
}

export interface StockItemOSResourceCollectionType {
  /** Data */
  data: StockItemOSResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemPositionResourceType {
  /** ID */
  id: string;
  /** Is main? */
  main: boolean;
  /** Notify on show? */
  notify_on_show: boolean;
  building: BuildingPreviewResourceType;
  stock: StockPreviewResourceType;
  rack: RackPreviewResourceType;
  /** Rack Position Row */
  rack_position_row: number;
  /** Rack Position Column */
  rack_position_column: number;
  /** Rack Position Part Row */
  rack_position_part_row?: number;
  /** Rack Position Part Column */
  rack_position_part_column?: number;
  /** Rack Position Part Index */
  rack_position_part_index?: number;
}

export interface StockItemProductCategoryCategoryResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
}

export interface StockItemProductCategoryCategoryResourceCollectionType {
  /** Data */
  data: StockItemProductCategoryCategoryResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemProductCategoryResourceType {
  /** ID */
  id: string;
  product_category: StockItemProductCategoryCategoryResourceType;
  product_subcategory: StockItemProductCategorySubcategoryResourceType;
  /** Order */
  order: number;
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at: number;
}

export interface StockItemProductCategoryResourceCollectionType {
  /** Data */
  data: StockItemProductCategoryResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemProductCategorySubcategoryResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Shortcut */
  shortcut?: string;
}

export interface StockItemProductCategorySubcategoryResourceCollectionType {
  /** Data */
  data: StockItemProductCategorySubcategoryResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemRelatedItemRelatedStockItemResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Alternative name */
  alternative_name?: string;
  /** Positions */
  positions: StockItemPositionResourceType[];
}

export interface StockItemRelatedItemRelatedStockItemResourceCollectionType {
  /** Data */
  data: StockItemRelatedItemRelatedStockItemResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemRelatedItemResourceType {
  /** ID */
  id: string;
  relatedStockItem: StockItemRelatedItemRelatedStockItemResourceType;
  /** Description */
  description?: string;
  /** Type */
  type?: StockItemRelatedItemResourceTypeType;
  /** Created at */
  created_at: number;
  /** Updated at */
  updated_at: number;
}

export interface StockItemRelatedItemResourceCollectionType {
  /** Data */
  data: StockItemResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockItemResourceType {
  /** ID */
  id?: string;
  /** Name */
  name: string;
  /** Alternative name */
  alternative_name?: string;
  /** Type */
  type: StockItemResourceTypeType;
  /** Quantity */
  quantity: number;
  /** Limit */
  limit?: number;
  /** Weight */
  weight?: string;
  stock_item_category?: StockItemCategoryResourceType;
  /** EAN */
  ean?: string;
  /** URL */
  url?: string;
  /** Note */
  note?: string;
  /**
   * Purchase Price
   * @format float
   */
  purchase_price?: number;
  /**
   * Sell Price
   * @format float
   */
  sell_price?: number;
  /**
   * Sell Price with VAP
   * @format float
   */
  sell_price_with_vat?: number;
  /** Accountancy */
  accountancy: boolean;
  /** Positions */
  positions: StockItemPositionResourceType[];
  image?: FilePreviewResourceType;
  /** Description */
  description?: string;
  /**
   * Marze
   * @format float
   */
  marze?: number;
  /**
   * Order Count
   * @format integer
   */
  order_count?: number;
  /** Is ordering? */
  is_ordering: boolean;
  /** Is pair? */
  is_pair: boolean;
  /** Is active? */
  is_active?: boolean;
  /** Related Items */
  related_items?: StockItemRelatedItemResourceType[];
  /** Product categories */
  product_categories?: StockItemProductCategoryResourceType[];
  /** Links */
  links: StockItemLinkResourceType[];
  /** OS */
  os: StockItemOSResourceType[];
  /** Is marze manual? */
  is_marze_manual: boolean;
  /** Is work time manual? */
  is_work_time_manual: boolean;
  /**
   * Work time
   * @format float
   */
  work_time?: number;
  /** Is included work price? */
  is_included_work_price: boolean;
  /** Is completed? */
  is_completed: boolean;
  /** OS text */
  os_text?: string;
  /** Product category text */
  product_category_text?: string;
  /** Informative price text */
  informative_price_text?: string;
  /** Related items text */
  related_items_text?: string;
  /** Order count text */
  order_count_text?: string;
  /** Recipes text */
  recipes_text?: string;
}

export interface StockItemResourceCollectionType {
  /** Data */
  data: StockItemResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockPreviewResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  map?: MapPreviewResourceType;
}

export interface StockResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  building: BuildingPreviewResourceType;
  map?: MapResourceType;
}

export interface StockResourceCollectionType {
  /** Data */
  data: StockResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface StockSelectResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
}

export interface TaskResourceType {
  /** ID */
  id: string;
  /** Number */
  number: string;
  /** Type */
  type: TaskResourceTypeType;
  /** Name */
  name?: string;
  os?: OSResourceType;
  contact_person?: ContactPersonResourceType;
  /** Status */
  status: TaskResourceStatusType;
  /** Due date */
  due_date?: number;
  /** Before realization */
  before_realization: boolean;
  assigned_user?: OSResourceType;
  /** Note */
  note?: string;
  /** OP Tasks */
  op_tasks: OPTaskResourceType[];
}

export interface TaskResourceCollectionType {
  /** Data */
  data: TaskResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface UserResourceType {
  /** ID */
  id: string;
  /** Name */
  name: string;
  /** Email */
  email: string;
  /** Permissions */
  permissions: string[];
  /** Is Active */
  is_active: boolean;
  /** First Name */
  first_name?: string;
  /** Last Name */
  last_name?: string;
  /** Phone */
  phone?: string;
  profile_photo?: FileResourceType;
}

export interface UserResourceCollectionType {
  /** Data */
  data: UserResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

export interface UserSettingResourceType {
  /** Key */
  key: string;
  /** Value */
  value?: string;
}

export interface UserSettingResourceCollectionType {
  /** Data */
  data: UserSettingResourceType[];
  links: PaginationLinksType;
  meta: PaginationMetaType;
}

/** Type */
export enum DirectoryAndFileInputTypeType {
  DIRECTORY = 'DIRECTORY',
  FILE = 'FILE',
  LINK = 'LINK',
}

/** Category */
export enum FotodokumentaceInputCategoryType {
  OPRAVA_PROBLEM = 'OPRAVA_PROBLEM',
  MONTAZ_SERVIS = 'MONTAZ_SERVIS',
  ZAMERENI = 'ZAMERENI',
  HELE = 'HELE',
}

/** Status */
export enum GoogleApiPlaceResourceStatusType {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
  INVALID_REQUEST = 'INVALID_REQUEST',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

/** Status */
export enum GoogleApiPlaceDetailStatusType {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
  INVALID_REQUEST = 'INVALID_REQUEST',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum GoogleApiPlaceDetailResultAddressComponentTypesType {
  StreetNumber = 'street_number',
  Premise = 'premise',
  Route = 'route',
  SublocalityLevel1 = 'sublocality_level_1',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  AdministrativeAreaLevel2 = 'administrative_area_level_2',
  Country = 'country',
  PostalCode = 'postal_code',
  PostalTown = 'postal_town',
  Neighborhood = 'neighborhood',
}

/** Type */
export enum MapInputTypeType {
  AREAL = 'AREAL',
  BUILDING = 'BUILDING',
  STOCK = 'STOCK',
  RACK = 'RACK',
}

/** Type */
export enum OpInputTypeType {
  NA = 'NA',
  S = 'S',
  SZ = 'SZ',
}

/** Type */
export enum OpRowInputTypeType {
  DODAVKA_A_MONTAZ = 'DODAVKA_A_MONTAZ',
  OPRAVA = 'OPRAVA',
  SERVIS = 'SERVIS',
  DODAVKA = 'DODAVKA',
  MONTAZ = 'MONTAZ',
  DEMONTAZ = 'DEMONTAZ',
}

/** Type */
export enum OsInputTypeType {
  SOUKROMA_OSOBA = 'SOUKROMA_OSOBA',
  ORGANIZACE = 'ORGANIZACE',
}

export enum OsInputCategoriesType {
  ODBERATEL = 'ODBERATEL',
  DODAVATEL = 'DODAVATEL',
  OSTATNI = 'OSTATNI',
}

export enum OsInputSubcategoriesType {
  KONCOVY_ZAKAZNIK = 'KONCOVY_ZAKAZNIK',
  GENERALNI_DODAVATEL_STAVEB = 'GENERALNI_DODAVATEL_STAVEB',
  PRACOVNICI = 'PRACOVNICI',
  OSTATNI = 'OSTATNI',
  HLAVNI = 'HLAVNI',
  VEDLEJSI = 'VEDLEJSI',
}

/** Informative price type */
export enum StockItemOsInputInformativePriceTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  KG = 'KG',
  KS = 'KS',
  M = 'M',
}

/** Order amount type */
export enum StockItemOsInputOrderAmountTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  KG = 'KG',
  KS = 'KS',
  M = 'M',
}

/** Type */
export enum StockItemRelatedItemInputTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  SOUVISEJICI_POLOZKA = 'SOUVISEJICI_POLOZKA',
}

/** Type */
export enum StockItemLinkInputTypeType {
  RECIPE = 'RECIPE',
  NAS = 'NAS',
}

/** Type */
export enum StockItemInputTypeType {
  Kg = 'kg',
  Ks = 'ks',
  M = 'm',
  None = 'none',
  Informative = 'informative',
}

/** Type */
export enum TaskInputTypeType {
  POPTAVKA = 'POPTAVKA',
  OBJEDNAVKA = 'OBJEDNAVKA',
  REKLAMACE = 'REKLAMACE',
  ZAMERENI = 'ZAMERENI',
  VYKRES = 'VYKRES',
  VYROBA = 'VYROBA',
  PRIPRAVA_A_VYCHYSTANI = 'PRIPRAVA_A_VYCHYSTANI',
  LIBOVOLNY = 'LIBOVOLNY',
}

/** Status */
export enum TaskInputStatusType {
  ZADANO = 'ZADANO',
  VYRIZUJE_SE = 'VYRIZUJE_SE',
  HOTOVO = 'HOTOVO',
}

/** Type */
export enum DirectoryAndFileResourceTypeType {
  DIRECTORY = 'DIRECTORY',
  FILE = 'FILE',
}

/** Type */
export enum FileResourceTypeType {
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

/** Category */
export enum FotodokumentaceResourceCategoryType {
  OPRAVA_PROBLEM = 'OPRAVA_PROBLEM',
  MONTAZ_SERVIS = 'MONTAZ_SERVIS',
  ZAMERENI = 'ZAMERENI',
  HELE = 'HELE',
}

/** Type */
export enum MapResourceTypeType {
  AREAL = 'AREAL',
  BUILDING = 'BUILDING',
  STOCK = 'STOCK',
  RACK = 'RACK',
}

/** Background Color */
export enum MapObjectBackgroundType {
  AREAL = 'AREAL',
  BUILDING = 'BUILDING',
  STOCK = 'STOCK',
  RACK = 'RACK',
}

/** Link Type */
export enum MapObjectLinkTypeType {
  EMPTY = 'EMPTY',
  URL = 'URL',
  BUILDING = 'BUILDING',
  STOCK = 'STOCK',
  RACK = 'RACK',
  STOCK_ITEM = 'STOCK_ITEM',
}

/** Link Type */
export enum MapColumnLinkTypeType {
  EMPTY = 'EMPTY',
  URL = 'URL',
  BUILDING = 'BUILDING',
  STOCK = 'STOCK',
  RACK = 'RACK',
  STOCK_ITEM = 'STOCK_ITEM',
}

/** Type */
export enum OpResourceTypeType {
  NA = 'NA',
  S = 'S',
  SZ = 'SZ',
}

/** Type */
export enum OpRowResourceTypeType {
  DODAVKA_A_MONTAZ = 'DODAVKA_A_MONTAZ',
  OPRAVA = 'OPRAVA',
  SERVIS = 'SERVIS',
  DODAVKA = 'DODAVKA',
  MONTAZ = 'MONTAZ',
  DEMONTAZ = 'DEMONTAZ',
}

/** Type */
export enum OsResourceTypeType {
  SOUKROMA_OSOBA = 'SOUKROMA_OSOBA',
  ORGANIZACE = 'ORGANIZACE',
}

export enum OsResourceCategoriesType {
  ODBERATEL = 'ODBERATEL',
  DODAVATEL = 'DODAVATEL',
  OSTATNI = 'OSTATNI',
}

export enum OsResourceSubcategoriesType {
  KONCOVY_ZAKAZNIK = 'KONCOVY_ZAKAZNIK',
  GENERALNI_DODAVATEL_STAVEB = 'GENERALNI_DODAVATEL_STAVEB',
  PRACOVNICI = 'PRACOVNICI',
  OSTATNI = 'OSTATNI',
  HLAVNI = 'HLAVNI',
  VEDLEJSI = 'VEDLEJSI',
}

/** Operator */
export enum FormFieldConditionResourceOperatorType {
  IS_TEXT_VALUE = 'IS_TEXT_VALUE',
  IS_ONE_OF_VALUE = 'IS_ONE_OF_VALUE',
  IS_MULTIPLE_VALUES = 'IS_MULTIPLE_VALUES',
}

/** Type */
export enum FormFieldResourceTypeType {
  Text = 'text',
  Select = 'select',
  Number = 'number',
  Image = 'image',
  File = 'file',
}

/** Type */
export enum RecipeCommentResourceTypeType {
  POZOR = 'POZOR',
  NEFUNGUJE = 'NEFUNGUJE',
  VIM_JAK_LEPE = 'VIM_JAK_LEPE',
  NEROZUMIM = 'NEROZUMIM',
}

/** Type */
export enum StockItemLinkResourceTypeType {
  RECIPE = 'RECIPE',
  NAS = 'NAS',
}

/** Informative price type */
export enum StockItemOsResourceInformativePriceTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  KG = 'KG',
  KS = 'KS',
  M = 'M',
}

/** Order amount type */
export enum StockItemOsResourceOrderAmountTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  KG = 'KG',
  KS = 'KS',
  M = 'M',
}

/** Type */
export enum StockItemRelatedItemResourceTypeType {
  PAR = 'PAR',
  SADA = 'SADA',
  SOUVISEJICI_POLOZKA = 'SOUVISEJICI_POLOZKA',
}

/** Type */
export enum StockItemResourceTypeType {
  Kg = 'kg',
  Ks = 'ks',
  M = 'm',
  None = 'none',
  Informative = 'informative',
}

/** Type */
export enum TaskResourceTypeType {
  POPTAVKA = 'POPTAVKA',
  OBJEDNAVKA = 'OBJEDNAVKA',
  REKLAMACE = 'REKLAMACE',
  ZAMERENI = 'ZAMERENI',
  VYKRES = 'VYKRES',
  VYROBA = 'VYROBA',
  PRIPRAVA_A_VYCHYSTANI = 'PRIPRAVA_A_VYCHYSTANI',
  LIBOVOLNY = 'LIBOVOLNY',
}

/** Status */
export enum TaskResourceStatusType {
  ZADANO = 'ZADANO',
  VYRIZUJE_SE = 'VYRIZUJE_SE',
  HOTOVO = 'HOTOVO',
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    instance,
    secure,
    format,
    securityWorker,
  }: {
    instance: AxiosInstance;
    secure?: boolean;
    format?: ResponseType;
    securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  }) {
    this.instance = instance;
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title System Vrata - API
 * @version 0.1
 * @baseUrl http://localhost:8000/api/
 * @contact <tompa96@outlook.com>
 *
 * API endpoints of System Vrata
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * @description Login
     *
     * @tags Auth
     * @name Login
     * @summary Login
     * @request POST:/auth/login
     */
    login: (data: LoginInputType, params: RequestParams = {}) =>
      this.request<MeResourceType, any>({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Logout
     *
     * @tags Auth
     * @name Logout
     * @summary Logout
     * @request POST:/auth/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Returns detail of me
     *
     * @tags Auth
     * @name GetMe
     * @summary Get Me
     * @request GET:/auth/me
     */
    getMe: (params: RequestParams = {}) =>
      this.request<MeResourceType, any>({
        path: `/auth/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Set Settings to User
     *
     * @tags Auth
     * @name SetSettings
     * @summary Set Settings to User
     * @request POST:/auth/settings
     */
    setSettings: (data: UserSettingsInputType, params: RequestParams = {}) =>
      this.request<MeResourceType, any>({
        path: `/auth/settings`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of permissions
     *
     * @tags Auth
     * @name GetPermissions
     * @summary Get list of Permissions
     * @request GET:/auth/permissions
     */
    getPermissions: (params: RequestParams = {}) =>
      this.request<PermissionsResourceType, any>({
        path: `/auth/permissions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  branch = {
    /**
     * @description Returns list of branches
     *
     * @tags Branch
     * @name GetBranches
     * @summary Get list of branches
     * @request POST:/branch/all
     */
    getBranches: (params: RequestParams = {}) =>
      this.request<BranchResourceCollectionType, any>({
        path: `/branch/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Return Branches export
     *
     * @tags Branch
     * @name GetBranchesExport
     * @summary Get Branches export
     * @request POST:/branch/export
     */
    getBranchesExport: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/branch/export`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Returns detail of Branch
     *
     * @tags Branch
     * @name GetBranchDetail
     * @summary Get Branch detail
     * @request GET:/branch/{id}
     */
    getBranchDetail: (id: any, params: RequestParams = {}) =>
      this.request<BranchResourceType, any>({
        path: `/branch/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Branch
     *
     * @tags Branch
     * @name UpdateBranch
     * @summary Update Branch
     * @request PUT:/branch/{id}
     */
    updateBranch: (
      id: any,
      data: BranchInputType,
      params: RequestParams = {},
    ) =>
      this.request<BranchResourceType, any>({
        path: `/branch/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Branch
     *
     * @tags Branch
     * @name DeleteBranch
     * @summary Delete Branch
     * @request DELETE:/branch/{id}
     */
    deleteBranch: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/branch/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Branch
     *
     * @tags Branch
     * @name CreateBranch
     * @summary Create Branch
     * @request POST:/branch
     */
    createBranch: (data: BranchInputType, params: RequestParams = {}) =>
      this.request<BranchResourceType, any>({
        path: `/branch`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  building = {
    /**
     * @description Returns list of Buildings
     *
     * @tags Building
     * @name GetBuildings
     * @summary Get list of Buildings
     * @request POST:/building/all
     */
    getBuildings: (params: RequestParams = {}) =>
      this.request<BuildingResourceType, any>({
        path: `/building/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Building
     *
     * @tags Building
     * @name GetBuildingDetail
     * @summary Get Building detail
     * @request GET:/building/{id}
     */
    getBuildingDetail: (id: any, params: RequestParams = {}) =>
      this.request<BuildingResourceType, any>({
        path: `/building/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Building
     *
     * @tags Building
     * @name UpdateBuilding
     * @summary Update Building
     * @request PUT:/building/{id}
     */
    updateBuilding: (
      id: any,
      data: BuildingInputType,
      params: RequestParams = {},
    ) =>
      this.request<BuildingResourceType, any>({
        path: `/building/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Building
     *
     * @tags Building
     * @name DeleteBuilding
     * @summary Delete Building
     * @request DELETE:/building/{id}
     */
    deleteBuilding: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/building/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Building
     *
     * @tags Building
     * @name CreateBuilding
     * @summary Create Building
     * @request POST:/building
     */
    createBuilding: (data: BuildingInputType, params: RequestParams = {}) =>
      this.request<BuildingResourceType, any>({
        path: `/building`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  contactPerson = {
    /**
     * @description Returns list of Contact Persons
     *
     * @tags Contact Person
     * @name GetContactPersons
     * @summary Get list of Contact Persons
     * @request POST:/contact-person/all
     */
    getContactPersons: (params: RequestParams = {}) =>
      this.request<ContactPersonResourceCollectionType, any>({
        path: `/contact-person/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Contact Person
     *
     * @tags Contact Person
     * @name GetContactPersonDetail
     * @summary Get Contact Person detail
     * @request GET:/contact-person/{id}
     */
    getContactPersonDetail: (id: any, params: RequestParams = {}) =>
      this.request<ContactPersonResourceType, any>({
        path: `/contact-person/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Contact Person
     *
     * @tags Contact Person
     * @name UpdateContactPerson
     * @summary Update Contact Person
     * @request PUT:/contact-person/{id}
     */
    updateContactPerson: (
      id: any,
      data: ContactPersonInputType,
      params: RequestParams = {},
    ) =>
      this.request<ContactPersonResourceType, any>({
        path: `/contact-person/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Contact Person
     *
     * @tags Contact Person
     * @name DeleteContactPerson
     * @summary Delete Contact Person
     * @request DELETE:/contact-person/{id}
     */
    deleteContactPerson: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contact-person/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Contact Person
     *
     * @tags Contact Person
     * @name CreateContactPerson
     * @summary Create Contact Person
     * @request POST:/contact-person
     */
    createContactPerson: (
      data: ContactPersonInputType,
      params: RequestParams = {},
    ) =>
      this.request<ContactPersonResourceType, any>({
        path: `/contact-person`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  directoryAndFile = {
    /**
     * @description Returns list of Directories or Files
     *
     * @tags Directory and File
     * @name GetDirectoryAndFile
     * @summary Get list of Directories or Files
     * @request GET:/directory-and-file
     */
    getDirectoryAndFile: (
      query?: {
        /** path */
        path?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryAndFileResourceType[], any>({
        path: `/directory-and-file`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Directory and File
     *
     * @tags Directory and File
     * @name CreateDirectoryAndFile
     * @summary Create Directory and File
     * @request POST:/directory-and-file
     */
    createDirectoryAndFile: (
      data: DirectoryAndFileInputType,
      params: RequestParams = {},
    ) =>
      this.request<DirectoryAndFileResourceType, any>({
        path: `/directory-and-file`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of directory and file
     *
     * @tags Directory and File
     * @name GetDirectoryAndFileDetail
     * @summary Get directory and file detail
     * @request GET:/directory-and-file/{id}
     */
    getDirectoryAndFileDetail: (id: any, params: RequestParams = {}) =>
      this.request<DirectoryAndFileResourceType, any>({
        path: `/directory-and-file/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Directory and File
     *
     * @tags Directory and File
     * @name UpdateDirectoryAndFile
     * @summary Update Directory and File
     * @request PUT:/directory-and-file/{id}
     */
    updateDirectoryAndFile: (
      id: any,
      data: DirectoryAndFileInputType,
      params: RequestParams = {},
    ) =>
      this.request<DirectoryAndFileResourceType, any>({
        path: `/directory-and-file/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Directory and File
     *
     * @tags Directory and File
     * @name DeleteDirectoryAndFile
     * @summary Delete Directory and File
     * @request DELETE:/directory-and-file/{id}
     */
    deleteDirectoryAndFile: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/directory-and-file/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  fotodokumentace = {
    /**
     * @description Returns list of fotodokumentace
     *
     * @tags Fotodokumentace
     * @name GetFotodokumentace
     * @summary Get list of fotodokumentace
     * @request POST:/fotodokumentace/all
     */
    getFotodokumentace: (params: RequestParams = {}) =>
      this.request<FotodokumentaceResourceCollectionType, any>({
        path: `/fotodokumentace/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of fotodokumentace
     *
     * @tags Fotodokumentace
     * @name GetFotodokumentaceDetail
     * @summary Get fotodokumentace detail
     * @request GET:/fotodokumentace/{id}
     */
    getFotodokumentaceDetail: (id: any, params: RequestParams = {}) =>
      this.request<FotodokumentaceResourceType, any>({
        path: `/fotodokumentace/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update fotodokumentace
     *
     * @tags Fotodokumentace
     * @name UpdateFotodokumentace
     * @summary Update fotodokumentace
     * @request PUT:/fotodokumentace/{id}
     */
    updateFotodokumentace: (
      id: any,
      data: FotodokumentaceInputType,
      params: RequestParams = {},
    ) =>
      this.request<FotodokumentaceResourceType, any>({
        path: `/fotodokumentace/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete fotodokumentace
     *
     * @tags Fotodokumentace
     * @name DeleteFotodokumentace
     * @summary Delete fotodokumentace
     * @request DELETE:/fotodokumentace/{id}
     */
    deleteFotodokumentace: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/fotodokumentace/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create fotodokumentace
     *
     * @tags Fotodokumentace
     * @name CreateFotodokumentace
     * @summary Create fotodokumentace
     * @request POST:/fotodokumentace
     */
    createFotodokumentace: (
      data: FotodokumentaceInputType,
      params: RequestParams = {},
    ) =>
      this.request<FotodokumentaceResourceType, any>({
        path: `/fotodokumentace`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate zip of fotodokumentace images
     *
     * @tags Fotodokumentace
     * @name GenerateZip
     * @summary Generate zip of fotodokumentace images
     * @request GET:/fotodokumentace/{id}/zip
     */
    generateZip: (id: any, params: RequestParams = {}) =>
      this.request<FileResourceType, any>({
        path: `/fotodokumentace/${id}/zip`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  googleApi = {
    /**
     * @description Get place result from google api
     *
     * @tags GooogleApi
     * @name GetPlace
     * @summary Get place result from google api
     * @request GET:/google-api/place
     */
    getPlace: (
      query?: {
        /** Query to request in google place api */
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GoogleApiPlaceResourceType, any>({
        path: `/google-api/place`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get place detail result from google api
     *
     * @tags GooogleApi
     * @name GetPlaceDetail
     * @summary Get place detail result from google api
     * @request GET:/google-api/place-detail
     */
    getPlaceDetail: (
      query: {
        /** place_id to request in google place api */
        place_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GoogleApiPlaceDetailType, any>({
        path: `/google-api/place-detail`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  import = {
    /**
     * @description Import os from Profis
     *
     * @tags Import
     * @name Os
     * @summary Import os from Profis
     * @request POST:/import/os
     */
    os: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/import/os`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Import contact person from Profis
     *
     * @tags Import
     * @name ContactPerson
     * @summary Import contact person from Profis
     * @request POST:/import/contact-person
     */
    contactPerson: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/import/contact-person`,
        method: 'POST',
        ...params,
      }),
  };
  map = {
    /**
     * @description Returns list of Maps
     *
     * @tags Map
     * @name GetMaps
     * @summary Get list of Maps
     * @request POST:/map/all
     */
    getMaps: (params: RequestParams = {}) =>
      this.request<MapResourceCollectionType, any>({
        path: `/map/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Map
     *
     * @tags Map
     * @name GetMapDetail
     * @summary Get Map detail
     * @request GET:/map/{id}
     */
    getMapDetail: (id: any, params: RequestParams = {}) =>
      this.request<MapResourceType, any>({
        path: `/map/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Map
     *
     * @tags Map
     * @name UpdateMap
     * @summary Update Map
     * @request PUT:/map/{id}
     */
    updateMap: (id: any, data: MapInputType, params: RequestParams = {}) =>
      this.request<MapResourceType, any>({
        path: `/map/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Map
     *
     * @tags Map
     * @name DeleteMap
     * @summary Delete Map
     * @request DELETE:/map/{id}
     */
    deleteMap: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/map/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Map
     *
     * @tags Map
     * @name CreateMap
     * @summary Create Map
     * @request POST:/map
     */
    createMap: (data: MapInputType, params: RequestParams = {}) =>
      this.request<MapResourceType, any>({
        path: `/map`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  op = {
    /**
     * @description Returns list of ops
     *
     * @tags OP
     * @name GetOp
     * @summary Get list of ops
     * @request POST:/op/all
     */
    getOp: (params: RequestParams = {}) =>
      this.request<OPResourceCollectionType, any>({
        path: `/op/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Return OP export
     *
     * @tags OP
     * @name GetOpExport
     * @summary Get OP export
     * @request POST:/op/export
     */
    getOpExport: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/op/export`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Returns detail of op
     *
     * @tags OP
     * @name GetOpDetail
     * @summary Get op detail
     * @request GET:/op/{id}
     */
    getOpDetail: (id: any, params: RequestParams = {}) =>
      this.request<OPResourceType, any>({
        path: `/op/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update OP
     *
     * @tags OP
     * @name UpdateOp
     * @summary Update OP
     * @request PUT:/op/{id}
     */
    updateOp: (id: any, data: OPInputType, params: RequestParams = {}) =>
      this.request<OPResourceType, any>({
        path: `/op/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete OP
     *
     * @tags OP
     * @name DeleteOp
     * @summary Delete OP
     * @request DELETE:/op/{id}
     */
    deleteOp: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/op/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create OP
     *
     * @tags OP
     * @name CreateOp
     * @summary Create OP
     * @request POST:/op
     */
    createOp: (data: OPInputType, params: RequestParams = {}) =>
      this.request<OPResourceType, any>({
        path: `/op`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Note
     *
     * @tags OP
     * @name UpdateNote
     * @summary Update Note
     * @request PATCH:/op/{id}/note
     */
    updateNote: (
      id: string,
      data: OPNoteInputType,
      params: RequestParams = {},
    ) =>
      this.request<OPResourceType, any>({
        path: `/op/${id}/note`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  opRow = {
    /**
     * @description Returns list of OP rows
     *
     * @tags OP Row
     * @name GetOpRow
     * @summary Get list of OP rows
     * @request POST:/op-row/all
     */
    getOpRow: (
      query?: {
        /**
         * ID
         * @example "00000000-0000-0000-0000-000000000000"
         */
        op_id?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<OPRowResourceCollectionType, any>({
        path: `/op-row/all`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Return OP rows export
     *
     * @tags OP Row
     * @name GetOpRowExport
     * @summary Get OP rows export
     * @request POST:/op-row/export
     */
    getOpRowExport: (
      query?: {
        /**
         * ID
         * @example "00000000-0000-0000-0000-000000000000"
         */
        op_id?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/op-row/export`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Returns detail of OP Row
     *
     * @tags OP Row
     * @name GetOpRowDetail
     * @summary Get OP Row detail
     * @request GET:/op-row/{id}
     */
    getOpRowDetail: (id: any, params: RequestParams = {}) =>
      this.request<OPResourceType, any>({
        path: `/op-row/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update OP Row
     *
     * @tags OP Row
     * @name UpdateOpRow
     * @summary Update OP Row
     * @request PUT:/op-row/{id}
     */
    updateOpRow: (id: any, data: OPRowInputType, params: RequestParams = {}) =>
      this.request<OPRowResourceType, any>({
        path: `/op-row/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete OP Row
     *
     * @tags OP Row
     * @name DeleteOpRow
     * @summary Delete OP Row
     * @request DELETE:/op-row/{id}
     */
    deleteOpRow: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/op-row/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create OP Row
     *
     * @tags OP Row
     * @name CreateOpRow
     * @summary Create OP Row
     * @request POST:/op-row
     */
    createOpRow: (data: OPRowInputType, params: RequestParams = {}) =>
      this.request<OPRowResourceType, any>({
        path: `/op-row`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  os = {
    /**
     * @description Returns list of os
     *
     * @tags OS
     * @name GetOs
     * @summary Get list of os
     * @request POST:/os/all
     */
    getOs: (params: RequestParams = {}) =>
      this.request<OSResourceCollectionType, any>({
        path: `/os/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Return OS export
     *
     * @tags OS
     * @name GetOsExport
     * @summary Get OS export
     * @request POST:/os/export
     */
    getOsExport: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/os/export`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Returns detail of os
     *
     * @tags OS
     * @name GetOsDetail
     * @summary Get os detail
     * @request GET:/os/{id}
     */
    getOsDetail: (id: any, params: RequestParams = {}) =>
      this.request<OSResourceType, any>({
        path: `/os/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update OS
     *
     * @tags OS
     * @name UpdateOs
     * @summary Update OS
     * @request PUT:/os/{id}
     */
    updateOs: (id: any, data: OSInputType, params: RequestParams = {}) =>
      this.request<OSResourceType, any>({
        path: `/os/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete OS
     *
     * @tags OS
     * @name DeleteOs
     * @summary Delete OS
     * @request DELETE:/os/{id}
     */
    deleteOs: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/os/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create OS
     *
     * @tags OS
     * @name CreateOs
     * @summary Create OS
     * @request POST:/os
     */
    createOs: (data: OSInputType, params: RequestParams = {}) =>
      this.request<OSResourceType, any>({
        path: `/os`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  productCategory = {
    /**
     * @description Returns list of product categories
     *
     * @tags Product Category
     * @name GetProductCategories
     * @summary Get list of product categories
     * @request POST:/product-category/all
     */
    getProductCategories: (params: RequestParams = {}) =>
      this.request<ProductCategoryResourceCollectionType, any>({
        path: `/product-category/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of product category
     *
     * @tags Product Category
     * @name GetProductCategoryDetail
     * @summary Get product category detail
     * @request GET:/product-category/{id}
     */
    getProductCategoryDetail: (id: any, params: RequestParams = {}) =>
      this.request<ProductCategoryResourceType, any>({
        path: `/product-category/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Product Category
     *
     * @tags Product Category
     * @name UpdateProductCategory
     * @summary Update Product Category
     * @request PUT:/product-category/{id}
     */
    updateProductCategory: (
      id: any,
      data: ProductCategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoryResourceType, any>({
        path: `/product-category/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Product Category
     *
     * @tags Product Category
     * @name CreateProductCategory
     * @summary Create Product Category
     * @request POST:/product-category
     */
    createProductCategory: (
      data: ProductCategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoryResourceType, any>({
        path: `/product-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  product = {
    /**
     * @description Returns list of products
     *
     * @tags Product
     * @name GetProducts
     * @summary Get list of products
     * @request POST:/product/all
     */
    getProducts: (params: RequestParams = {}) =>
      this.request<ProductResourceCollectionType, any>({
        path: `/product/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of producty
     *
     * @tags Product
     * @name GetProductDetail
     * @summary Get product detail
     * @request GET:/product/{id}
     */
    getProductDetail: (id: any, params: RequestParams = {}) =>
      this.request<ProductResourceType, any>({
        path: `/product/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Product
     *
     * @tags Product
     * @name UpdateProduct
     * @summary Update Product
     * @request PUT:/product/{id}
     */
    updateProduct: (
      id: any,
      data: ProductInputType,
      params: RequestParams = {},
    ) =>
      this.request<ProductResourceType, any>({
        path: `/product/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Product
     *
     * @tags Product
     * @name CreateProduct
     * @summary Create Product
     * @request POST:/product
     */
    createProduct: (data: ProductInputType, params: RequestParams = {}) =>
      this.request<ProductResourceType, any>({
        path: `/product`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  productSubcategory = {
    /**
     * @description Returns list of product subcategories
     *
     * @tags Product Subcategory
     * @name GetProductSubcategories
     * @summary Get list of product subcategories
     * @request POST:/product-subcategory/all
     */
    getProductSubcategories: (params: RequestParams = {}) =>
      this.request<ProductSubcategoryResourceCollectionType, any>({
        path: `/product-subcategory/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of product subcategory
     *
     * @tags Product Subcategory
     * @name GetProductSubcategoryDetail
     * @summary Get product subccategory detail
     * @request GET:/product-subcategory/{id}
     */
    getProductSubcategoryDetail: (id: any, params: RequestParams = {}) =>
      this.request<ProductSubcategoryResourceType, any>({
        path: `/product-subcategory/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Product Subcategory
     *
     * @tags Product Subcategory
     * @name UpdateProductSubcategory
     * @summary Update Product Subcategory
     * @request PUT:/product-subcategory/{id}
     */
    updateProductSubcategory: (
      id: any,
      data: ProductSubcategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<ProductSubcategoryResourceType, any>({
        path: `/product-subcategory/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Product Subcategory
     *
     * @tags Product Subcategory
     * @name CreateProductSubcategory
     * @summary Create Product Subcategory
     * @request POST:/product-subcategory
     */
    createProductSubcategory: (
      data: ProductSubcategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<ProductSubcategoryResourceType, any>({
        path: `/product-subcategory`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  rack = {
    /**
     * @description Returns list of Racks
     *
     * @tags Rack
     * @name GetRacks
     * @summary Get list of Racks
     * @request POST:/rack/all
     */
    getRacks: (params: RequestParams = {}) =>
      this.request<RackResourceType, any>({
        path: `/rack/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Rack
     *
     * @tags Rack
     * @name GetRackDetail
     * @summary Get Rack detail
     * @request GET:/rack/{id}
     */
    getRackDetail: (id: any, params: RequestParams = {}) =>
      this.request<RackResourceType, any>({
        path: `/rack/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Rack
     *
     * @tags Rack
     * @name UpdateRack
     * @summary Update Rack
     * @request PUT:/rack/{id}
     */
    updateRack: (id: any, data: RackInputType, params: RequestParams = {}) =>
      this.request<RackResourceType, any>({
        path: `/rack/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Rack
     *
     * @tags Rack
     * @name DeleteRack
     * @summary Delete Rack
     * @request DELETE:/rack/{id}
     */
    deleteRack: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rack/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Rack
     *
     * @tags Rack
     * @name CreateRack
     * @summary Create Rack
     * @request POST:/rack
     */
    createRack: (data: RackInputType, params: RequestParams = {}) =>
      this.request<RackResourceType, any>({
        path: `/rack`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  recipe = {
    /**
     * @description Returns list of Recipes
     *
     * @tags Recipe
     * @name GetRecipes
     * @summary Get list of Recipes
     * @request POST:/recipe/all-table
     */
    getRecipes: (params: RequestParams = {}) =>
      this.request<RecipeResourceCollectionType, any>({
        path: `/recipe/all-table`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  settings = {
    /**
     * @description Returns list of Settings
     *
     * @tags Settings
     * @name GetSettings
     * @summary Get list of Settings
     * @request GET:/settings
     */
    getSettings: (params: RequestParams = {}) =>
      this.request<SettingResourceCollectionType, any>({
        path: `/settings`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create or edit settings and return all
     *
     * @tags Settings
     * @name CreateOrUpdateSettings
     * @summary Create or edit settings
     * @request POST:/settings
     */
    createOrUpdateSettings: (
      data: SettingInputType,
      params: RequestParams = {},
    ) =>
      this.request<SettingResourceCollectionType, any>({
        path: `/settings`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stock = {
    /**
     * @description Returns list of Stocks
     *
     * @tags Stock
     * @name GetStocks
     * @summary Get list of Stocks
     * @request POST:/stock/all
     */
    getStocks: (params: RequestParams = {}) =>
      this.request<StockResourceCollectionType, any>({
        path: `/stock/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Stock
     *
     * @tags Stock
     * @name GetStockDetail
     * @summary Get Stock detail
     * @request GET:/stock/{id}
     */
    getStockDetail: (id: any, params: RequestParams = {}) =>
      this.request<StockResourceType, any>({
        path: `/stock/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Stock
     *
     * @tags Stock
     * @name UpdateStock
     * @summary Update Stock
     * @request PUT:/stock/{id}
     */
    updateStock: (id: any, data: StockInputType, params: RequestParams = {}) =>
      this.request<StockResourceType, any>({
        path: `/stock/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Stock
     *
     * @tags Stock
     * @name DeleteStock
     * @summary Delete Stock
     * @request DELETE:/stock/{id}
     */
    deleteStock: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stock/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Stock
     *
     * @tags Stock
     * @name CreateStock
     * @summary Create Stock
     * @request POST:/stock
     */
    createStock: (data: StockInputType, params: RequestParams = {}) =>
      this.request<StockResourceType, any>({
        path: `/stock`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stockItemCategory = {
    /**
     * @description Returns list of StockItemCategories
     *
     * @tags Stock Item Category
     * @name GetStockItemCategories
     * @summary Get list of StockItemCategories
     * @request POST:/stock-item-category/all
     */
    getStockItemCategories: (params: RequestParams = {}) =>
      this.request<RackResourceType, any>({
        path: `/stock-item-category/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Stock Item Category
     *
     * @tags Stock Item Category
     * @name GetStockItemCategoryDetail
     * @summary Get Stock Item Category detail
     * @request GET:/stock-item-category/{id}
     */
    getStockItemCategoryDetail: (id: any, params: RequestParams = {}) =>
      this.request<StockItemCategoryResourceType, any>({
        path: `/stock-item-category/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Stock Item Category
     *
     * @tags StockItemCategory
     * @name UpdateStockItemCategory
     * @summary Update Stock Item Category
     * @request PUT:/stock-item-category/{id}
     */
    updateStockItemCategory: (
      id: any,
      data: StockItemCategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<StockItemCategoryResourceType, any>({
        path: `/stock-item-category/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Stock Item Category
     *
     * @tags StockItemCategory
     * @name DeleteStockItemCategory
     * @summary Delete Stock Item Category
     * @request DELETE:/stock-item-category/{id}
     */
    deleteStockItemCategory: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stock-item-category/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Stock Item Category
     *
     * @tags StockItemCategory
     * @name CreateStockItemCategory
     * @summary Create Stock Item Category
     * @request POST:/stock-item-category
     */
    createStockItemCategory: (
      data: StockItemCategoryInputType,
      params: RequestParams = {},
    ) =>
      this.request<StockItemCategoryResourceType, any>({
        path: `/stock-item-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stockItem = {
    /**
     * @description Returns list of Stock Items
     *
     * @tags Stock Item
     * @name GetStockItems
     * @summary Get list of Stock Items
     * @request POST:/stock-item/all
     */
    getStockItems: (params: RequestParams = {}) =>
      this.request<StockItemResourceCollectionType, any>({
        path: `/stock-item/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Return stock item export
     *
     * @tags Stock Item
     * @name GetStockItemExport
     * @summary Get Stock Item export
     * @request POST:/stock-item/export
     */
    getStockItemExport: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stock-item/export`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Mass update of Stock Items
     *
     * @tags Stock Item
     * @name MassUpdate
     * @summary Mass update of Stock Items
     * @request POST:/stock-item/mass-update
     */
    massUpdate: (params: RequestParams = {}) =>
      this.request<StockItemResourceCollectionType, any>({
        path: `/stock-item/mass-update`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of Stock Item
     *
     * @tags Stock Item
     * @name GetStockItemDetail
     * @summary Get Stock Item detail
     * @request GET:/stock-item/{id}
     */
    getStockItemDetail: (id: any, params: RequestParams = {}) =>
      this.request<StockItemResourceType, any>({
        path: `/stock-item/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Stock Item
     *
     * @tags Stock Item
     * @name UpdateStockItem
     * @summary Update Stock Item
     * @request PUT:/stock-item/{id}
     */
    updateStockItem: (
      id: any,
      data: StockItemInputType,
      params: RequestParams = {},
    ) =>
      this.request<StockItemResourceType, any>({
        path: `/stock-item/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Stock Item
     *
     * @tags Stock Item
     * @name DeleteStockItem
     * @summary Delete Stock Item
     * @request DELETE:/stock-item/{id}
     */
    deleteStockItem: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stock-item/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Stock Item
     *
     * @tags Stock Item
     * @name CreateStockItem
     * @summary Create Stock Item
     * @request POST:/stock-item
     */
    createStockItem: (data: StockItemInputType, params: RequestParams = {}) =>
      this.request<StockItemResourceType, any>({
        path: `/stock-item`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change Stock Item Active State
     *
     * @tags Stock Item
     * @name ChangeActiveState
     * @summary Change Stock Item Active State
     * @request POST:/stock-item/{id}/change-active-state
     */
    changeActiveState: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stock-item/${id}/change-active-state`,
        method: 'POST',
        ...params,
      }),
  };
  task = {
    /**
     * @description Returns list of tasks
     *
     * @tags Task
     * @name GetTasks
     * @summary Get list of tasks
     * @request POST:/task/all
     */
    getTasks: (params: RequestParams = {}) =>
      this.request<TaskResourceCollectionType, any>({
        path: `/task/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of task
     *
     * @tags Task
     * @name GetTaskDetail
     * @summary Get task detail
     * @request GET:/task/{id}
     */
    getTaskDetail: (id: any, params: RequestParams = {}) =>
      this.request<TaskResourceType, any>({
        path: `/task/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Task
     *
     * @tags Task
     * @name UpdateTask
     * @summary Update Task
     * @request PUT:/task/{id}
     */
    updateTask: (id: any, data: TaskInputType, params: RequestParams = {}) =>
      this.request<TaskResourceType, any>({
        path: `/task/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Task
     *
     * @tags Task
     * @name DeleteTask
     * @summary Delete Task
     * @request DELETE:/task/{id}
     */
    deleteTask: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/task/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create Task
     *
     * @tags Task
     * @name CreateTask
     * @summary Create Task
     * @request POST:/task
     */
    createTask: (data: TaskInputType, params: RequestParams = {}) =>
      this.request<TaskResourceType, any>({
        path: `/task`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Note
     *
     * @tags Task
     * @name UpdateTaskNote
     * @summary Update Note
     * @request PATCH:/task/{id}/note
     */
    updateTaskNote: (
      id: string,
      data: TaskNoteInputType,
      params: RequestParams = {},
    ) =>
      this.request<TaskResourceType, any>({
        path: `/task/${id}/note`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Make Task Done
     *
     * @tags Task
     * @name MakeTaskDone
     * @summary Make Task Done
     * @request POST:/task/{id}/done
     */
    makeTaskDone: (id: string, params: RequestParams = {}) =>
      this.request<TaskResourceType, any>({
        path: `/task/${id}/done`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  user = {
    /**
     * @description Returns list of Users
     *
     * @tags User
     * @name GetUsers
     * @summary Get list of Users
     * @request POST:/user/all
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<UserResourceCollectionType, any>({
        path: `/user/all`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns detail of User
     *
     * @tags User
     * @name GetUserDetail
     * @summary Get User detail
     * @request GET:/user/{id}
     */
    getUserDetail: (id: any, params: RequestParams = {}) =>
      this.request<UserResourceType, any>({
        path: `/user/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update User
     *
     * @tags User
     * @name UpdateUser
     * @summary Update User
     * @request PUT:/user/{id}
     */
    updateUser: (id: any, data: UserInputType, params: RequestParams = {}) =>
      this.request<UserResourceType, any>({
        path: `/user/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete User
     *
     * @tags User
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/user/{id}
     */
    deleteUser: (id: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Create User
     *
     * @tags User
     * @name CreateUser
     * @summary Create User
     * @request POST:/user
     */
    createUser: (data: UserInputType, params: RequestParams = {}) =>
      this.request<UserResourceType, any>({
        path: `/user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
