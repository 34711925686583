import React, { ComponentProps, FC, RefObject } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

interface InputProps extends ComponentProps<'input'> {
  register?: UseFormRegisterReturn;
  inputRef?: RefObject<HTMLInputElement>;
}

const Input: FC<InputProps> = ({ register = {}, inputRef, ...props }) => (
  <input
    {...register}
    {...props}
    ref={register?.ref || props.ref || inputRef}
  />
);

export default styled(Input)`
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  padding: 0 1.6rem;
  height: 4rem;
  border: 1px solid #e0e2e3;
  border-radius: 4px;
  background: #ffffff;

  &:disabled {
    cursor: not-allowed;
    background-color: #eeeeee;
  }
`;
