import axios from 'axios';

import { signOut } from '../slices/AuthSlice';
import { getItem } from '../utils/LocalStorageUtils';
import { API_PATH } from './constants';
import { store } from './store';

const axiosInstance = axios.create({
  baseURL: `${API_PATH}/api`,
  maxContentLength: -1,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getItem<string>('accessToken');
    if (accessToken) {
      // @ts-ignore
      config.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      originalRequest?.url === '/auth/me'
    ) {
      return Promise.resolve();
    }

    if (error?.response?.status === 401) {
      store.dispatch(signOut());
      window.location.href = '/prihlaseni';
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
