import React, { FC } from 'react';

import Loader from '../../components/loader/Loader';
import './loading-page.scss';

const LoadingPage: FC = () => (
  <div className="loading-page">
    <Loader />
  </div>
);

export default LoadingPage;
